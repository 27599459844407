import Input from 'components/input';
import { ChipInfo } from 'design-system/chip/chip-info';
import Select from 'design-system/select';
import { TableRowTwoCols } from 'design-system/table/table-row';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDescargas } from 'selectors/descargas.selectors';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import { recetasActions, selectRecetaEditing } from 'store/recetas/recetasSlice';
import { Recipe, RECIPE_TYPE_HEADS, RECIPE_TYPE_KG } from 'utils/device/event/diet';
import { VERSION, isDeviceVersionAtLeast } from 'utils/helpers/validation';

const RecetaForm = ({ receta, editingEnabled }: { receta?: Recipe; editingEnabled?: boolean }) =>
  editingEnabled ? <RecetaFormEditable /> : receta ? <RecetaFormBase receta={receta} /> : null;
export default RecetaForm;

function RecetaFormEditable() {
  const recetaEditing = useSelector(selectRecetaEditing);
  return <RecetaFormBase receta={recetaEditing} editingEnabled />;
}

function RecetaFormBase({ receta, editingEnabled }: { receta: Recipe; editingEnabled?: boolean }) {
  const dispatch = useDispatch();

  const deviceSelected = useSelector(selectDeviceSelected);
  const isDeviceVersionFive = isDeviceVersionAtLeast(VERSION.FIVE, deviceSelected);

  const handleFormChange = (recetaEditing: Recipe) => dispatch(recetasActions.onFormChange(recetaEditing));

  const descargas = useSelector(selectDescargas);

  const { id, name, mixingTime, type, realHeadCount, unloadGuide } = receta || {};

  const onNombreChange = (value: string) => {
    handleFormChange({ ...receta, name: value });
  };

  const onTiempoMezclaChange = (value: number) => {
    handleFormChange({ ...receta, mixingTime: value });
  };
  const onTipoUnidadChange = (value: string) => {
    handleFormChange({
      ...receta,
      type: value as typeof RECIPE_TYPE_HEADS | typeof RECIPE_TYPE_KG,
    });
  };

  const onDescargaAsociadaChange = (value: string) => {
    handleFormChange({
      ...receta,
      unloadGuide: descargas.find((descarga) => descarga.id === Number(value)),
    });
  };

  const onCabezasRealesChange = (value: number) => {
    handleFormChange({ ...receta, realHeadCount: value });
  };

  const UNLOAD_GUIDES_OPTIONS = useMemo(
    () =>
      descargas.map((descarga) => ({
        name: descarga.name,
        value: `${descarga.id}`,
      })),
    [descargas]
  );

  const showSelectID = !!id;

  return (
    <div className="flex flex-col gap-2">
      <TableRowTwoCols>
        <div className={!showSelectID ? 'w-full mobile:col-span-2' : 'w-full'}>
          <Input
            placeholder="Nombre"
            label="Nombre"
            defaultValue={name || ''}
            onBlur={onNombreChange}
            disabled={!editingEnabled}
            maxLength={isDeviceVersionFive ? 25 : 14}
          />
        </div>
        {showSelectID && (
          <div className="mb-1 w-full">
            <ChipInfo>ID de receta {id}</ChipInfo>
          </div>
        )}
      </TableRowTwoCols>
      <TableRowTwoCols>
        <Input
          placeholder="Tiempo de Mezcla"
          label="Tiempo de Mezcla"
          defaultValue={mixingTime}
          type="number"
          onBlur={(value: string) => {
            onTiempoMezclaChange(Number(value));
          }}
          disabled={!editingEnabled}
          min={0}
          max={99}
        />
        <Select
          name="Tipo Unidad"
          label="Tipo Unidad"
          placeholder="Tipo Unidad"
          options={[
            { name: 'Cabezas', value: RECIPE_TYPE_HEADS },
            { name: 'Kg', value: RECIPE_TYPE_KG },
          ]}
          onChangeValue={(value: string) => {
            onTipoUnidadChange(value);
          }}
          value={type}
          disabled={!editingEnabled}
        />
      </TableRowTwoCols>
      <TableRowTwoCols>
        <Input
          placeholder="Cabezas reales/Kg"
          label="Cabezas reales/Kg"
          defaultValue={realHeadCount}
          type="number"
          onBlur={(value: string) => {
            onCabezasRealesChange(Number(value));
          }}
          disabled={!editingEnabled}
          min={0}
        />
        <Select
          name="Descarga asociada"
          label="Descarga asociada"
          placeholder="Sin descarga asociada"
          placeholderEnabled
          options={UNLOAD_GUIDES_OPTIONS}
          onChangeValue={(value: string) => {
            onDescargaAsociadaChange(value);
          }}
          value={`${unloadGuide?.id || ''}`}
          disabled={!editingEnabled}
        />
      </TableRowTwoCols>
    </div>
  );
}
