import { FullScreenLoader } from 'components/loader';
import TableSubsumView from 'components/table-subsum-view';
import ViewHeader from 'design-system/view-header';
import { useCalendarQuery } from 'hooks/useCalendarQuery';
import { useGetDeviceDownloadDataQuery } from 'store/devices/devicesApi';
import parseFunction from './parser';

function Descargas() {
  const {
    data: deviceDownloadData = [],
    isFetching: isDeviceDownloadDataFetching,
  } = useCalendarQuery(useGetDeviceDownloadDataQuery);

  return (
    <>
      {isDeviceDownloadDataFetching && <FullScreenLoader />}
      <ViewHeader title="Descargas" dateSelector="range" />
      <TableSubsumView
        data={deviceDownloadData}
        columnsName={'columnsDescargas'}
        parseFunction={parseFunction}
      />
    </>
  );
}

export default Descargas;
