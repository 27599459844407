import { FullScreenLoader } from 'components/loader';
import TableView from 'design-system/table-view';
import ViewHeader from 'design-system/view-header';
import { useCalendarQuery } from 'hooks/useCalendarQuery';
import { useColumns } from 'hooks/useColumns';
import { useMemo } from 'react';
import { useGetDeviceDataQuery } from 'store/devices/devicesApi';
import parseFunction from './parser';

function LecturaComederos() {
  const { columns, onColumnsViewChange } = useColumns('columnsLecturaComederos');

  const { data: deviceData = [], isFetching: isDeviceDataFetching } = useCalendarQuery(useGetDeviceDataQuery);
  const dataLecturaComederos = useMemo(() => parseFunction(deviceData), [deviceData]);

  return (
    <>
      {isDeviceDataFetching && <FullScreenLoader />}
      <ViewHeader title="Lectura de Comederos" dateSelector="range" />
      <TableView
        data={dataLecturaComederos}
        columns={columns}
        onColumnViewChange={onColumnsViewChange}
        options={{
          pagination: false,
          filter: true,
        }}
      />
    </>
  );
}

export default LecturaComederos;
