import AlertDialog from 'components/alert-dialog';
import { FullScreenLoader } from 'components/loader';
import SpaceDivider from 'components/space-divider';
import TareasPendientesBanner from 'components/tareas-pendientes-banner';
import { ChipQuestionMark } from 'design-system/chip/chip-question-mark';
import FormCard, { FormCardIcon } from 'design-system/form-card';
import ViewHeader from 'design-system/view-header';
import { useModal } from 'hooks/useModal';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectRecetas } from 'selectors/recetas.selectors';
import { selectDeviceSelectedSerialNumber } from 'store/devices/devicesSlice';
import { useEliminarRecetaMutation } from 'store/recetas/recetasApi';
import { recetasActions } from 'store/recetas/recetasSlice';
import { Recipe } from 'utils/device/event/diet';
import ModalAgregarReceta from './modal-agregar-receta';
import RecetaForm from './modal-agregar-receta/receta-form';
import TablaReceta from './tabla-receta';

function CreadorRecetas() {
  const dispatch = useDispatch();
  const deviceSelectedSerialNumber = useSelector(selectDeviceSelectedSerialNumber);
  const recetas = useSelector(selectRecetas);

  const [onEliminarReceta, { isLoading: isEliminarRecetaLoading }] = useEliminarRecetaMutation();

  const [isOpenAgregarReceta, onOpenAgregarReceta, onCloseAgregarReceta] = useModal();
  const [isOpenEliminarReceta, onOpenEliminarReceta, onCloseEliminarReceta, recetaToEliminar] = useModal<Recipe>();

  // Quick fix para resetear la UX de la receta cuando se cierra el modal
  const [isSavingData, setIsSavingData] = useState(false);

  const handleOpenAgregarReceta = (receta?: Recipe) => {
    if (!receta) {
      dispatch(recetasActions.resetReceta());
    } else dispatch(recetasActions.editReceta(receta));
    onOpenAgregarReceta(null);
  };

  const handleEliminarReceta = () => {
    if (recetaToEliminar && deviceSelectedSerialNumber)
      onEliminarReceta({
        serialNumber: deviceSelectedSerialNumber,
        receta: recetaToEliminar,
      });
    onCloseEliminarReceta();
  };

  useEffect(() => {
    setIsSavingData(false);
  }, [recetas]);

  return (
    <>
      {(isEliminarRecetaLoading || isSavingData) && <FullScreenLoader />}
      {isOpenAgregarReceta && (
        <ModalAgregarReceta
          isOpen={isOpenAgregarReceta}
          onClose={onCloseAgregarReceta}
          setIsSavingData={setIsSavingData}
        />
      )}
      {isOpenEliminarReceta && (
        <AlertDialog
          isOpen={isOpenEliminarReceta}
          onClose={onCloseEliminarReceta}
          title="Eliminar Receta"
          onPositiveClick={handleEliminarReceta}
          positiveLabel="Eliminar"
        >
          ¿Está seguro que desea eliminar la receta? Esta acción no se puede deshacer.
        </AlertDialog>
      )}
      <ViewHeader
        title="Creador de Recetas"
        action={{
          label: 'Crear',
          onClick: () => handleOpenAgregarReceta(),
          isProtected: true,
        }}
      />
      <div className="content-view px-8">
        <TareasPendientesBanner />
        <SpaceDivider />
        {recetas.length === 0 ? (
          <ChipQuestionMark>No se encontraron recetas para este equipo.</ChipQuestionMark>
        ) : (
          recetas.map((receta, index) => (
            <Fragment key={deviceSelectedSerialNumber + String(receta.id) + String(isSavingData) + index}>
              <FormCard
                title={receta.name}
                icon={<FormCardIcon>{receta.id}</FormCardIcon>}
                onCancel={() => onOpenEliminarReceta(receta)}
                onSave={() => handleOpenAgregarReceta(receta)}
                isCancelProtected
                isSaveProtected
                cancelLabel="Eliminar"
                saveLabel="Modificar"
                items={[{ value: <RecetaForm receta={receta} /> }, { value: <TablaReceta receta={receta} /> }]}
              />
              <SpaceDivider className="double" />
            </Fragment>
          ))
        )}
      </div>
    </>
  );
}

export default CreadorRecetas;
