import { Recipe } from './event/diet';
import { DeviceStock } from './stock';
import { UnloadGuide, Lot } from './lot';
import { Silo } from './event/cas';

export const DEVICE_TYPE_SIP = 'SIP-N';
export const DEVICE_TYPE_ITH = 'ITH-C';
export const DEVICE_TYPE_CMAS = 'CMAS';
export const DEVICE_TYPE_GAC = 'GAC';
export const DEVICE_TYPE_CAS = 'CAS';

export type DeviceType =
  | typeof DEVICE_TYPE_SIP
  | typeof DEVICE_TYPE_ITH
  | typeof DEVICE_TYPE_CMAS
  | typeof DEVICE_TYPE_GAC
  | typeof DEVICE_TYPE_CAS;

export type Device = {
  serialNumber: string;
  alias: string;
  type: DeviceType;
  sipnStock?: DeviceStock;
  averageFuelConsumptionPerKilometer?: any;
  availableFuelLiters?: number;
  fuelPrice?: number;
  associatedCmas?: any;
  fuelCostPerKilometer?: any;
  dryMatterModifiesRecipes?: boolean;
  fuelCostPerLitre?: number;
  mixerMaxCapacity?: number;
  recipes?: Recipe[];
  unloadGuides?: UnloadGuide[];
  lots?: Lot[];
  ver: string;
  lastDataRecievedDate?: string; // Formato ISO
  pumpUsers?: PumpUser[];
  pumpVehicles?: PumpVehicle[];
  fanTimers?: FanTimer[]; // ITH Timers
  associatedAfimilk?: string;
  silos?: Silo[]
};

export type DeviceItem = Device & { link?: string; isSelected?: boolean };

export function isDeviceSIP({ type }: Device) {
  return type === DEVICE_TYPE_SIP;
}
export function isDeviceITH({ type }: Device) {
  return type === DEVICE_TYPE_ITH;
}
export function isDeviceCmas({ type }: Device) {
  return type === DEVICE_TYPE_CMAS;
}
export function isDeviceGac({ type }: Device) {
  return type === DEVICE_TYPE_GAC;
}
export function isDeviceCas({ type }: Device) {
  return type === DEVICE_TYPE_CAS;
}

export type PumpUser = {
  availableLiters: number;
  maxLiters: number;
  id: number;
  keyId: number;
  manualAccess: string;
  mode: string;
  name: string;
  password: string;
  pumpUserType: string;
};

export type PumpVehicle = {
  id: number;
  name: string;
  keyId?: string
};

export type FanTimer = {
  id: '1' | '2' | '3' | '4' | '5' | '6' | '7';
  sector: '1' | '2';
  start: string;
  stop: string;
};
