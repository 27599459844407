import { FullScreenLoader } from 'components/loader';
import TableView from 'design-system/table-view';
import ViewHeader from 'design-system/view-header';
import { useCalendarQuery } from 'hooks/useCalendarQuery';
import { useColumns } from 'hooks/useColumns';
import { useModal } from 'hooks/useModal';
import { useMemo } from 'react';
import { useGetDeviceEventsHistoryQuery } from 'store/devices/devicesApi';
import EventoDetalle from './evento-detalle';
import parseFunction from './parser';

function EventosHistorico() {
  const { data: deviceEventsHistory = [], isFetching: isDeviceEventsFetching } = useCalendarQuery(
    useGetDeviceEventsHistoryQuery
  );

  const { columns } = useColumns('columnsRegistroEventos');
  const [isOpenEventoDetalle, onOpenEventoDetalle, onCloseEventoDetalle, element] = useModal();

  const dataEventsHistory = useMemo(
    () => parseFunction(deviceEventsHistory, (evento) => onOpenEventoDetalle(evento)),
    [deviceEventsHistory, onOpenEventoDetalle]
  );

  return (
    <>
      {isDeviceEventsFetching && <FullScreenLoader />}
      <EventoDetalle evento={element} isOpen={isOpenEventoDetalle} onClose={onCloseEventoDetalle} />
      <ViewHeader title="Registro de Eventos" dateSelector="range" />
      <TableView
        data={dataEventsHistory}
        columns={columns}
        options={{
          pagination: false,
          filter: true,
        }}
      />
    </>
  );
}

export default EventosHistorico;
