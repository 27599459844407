import { useState, useEffect, Fragment, useCallback } from 'react';
import { Checkbox, Chip } from '@material-ui/core';
import Table from './Table';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { customBodyRenderDate } from 'utils/helpers/body-renders';
import {
  redondearDosDecimales,
  redondearDosDecimalesToString,
} from 'utils/helpers/math';
import { isNumber } from 'utils/helpers/validation';

export type Semaforo = { key: string; isWarning: boolean; isDanger: boolean };

export type TableSubsumaRowValue = {
  rowType: 'value';
  isAccionManual?: boolean;
  isSelected?: boolean;
  semaforo?: Semaforo[];
  [key: string]: any;
};

export type TableSubsumaResultadoRow<
  AttributeToSum extends string,
  ExtraAttributeType extends string
> = {
  rowType: 'resultado';
  isResultadoFinal?: boolean;
  extra?: RowExtraAttributes<ExtraAttributeType, any>;
  isAnticipated?: boolean;
} & RowAttributesToSum<AttributeToSum>;

export type TableSubsumRow =
  | TableSubsumaRowValue
  | TableSubsumaResultadoRow<any, any>;

export type RowAttributesToSum<AttributeToSum extends string> = {
  [key in AttributeToSum]: [number, number];
};

export type RowExtraAttributes<ExtraAttributeType extends string, ValueType> = {
  [key in ExtraAttributeType]: { label: string; value: ValueType };
};

function TableSubsum({
  title = '',
  data = [],
  columnas,
  onToggleRow = () => {},
  onColumnsViewChange = () => {},
  tableOptions = {},
  withSelect = true,
}: {
  title: string;
  data: TableSubsumRow[];
  columnas: any[];
  onToggleRow?: (rowIndex: number) => void;
  onColumnsViewChange: (columns: any) => void;
  tableOptions?: any;
  withSelect?: boolean;
}) {
  const [columnasVisibles, setColumnasVisibles] = useState(columnas);

  function onColumnViewChange(newColumns: any) {
    setColumnasVisibles(newColumns);
    onColumnsViewChange(newColumns);
  }

  useEffect(() => {
    setColumnasVisibles(columnas);
  }, [columnas]);

  const customRowRender = useCallback(
    (tableRow: any[], _dataIndex: number, rowIndex: number) => {
      const {
        rowType,
        isResultadoFinal,
        semaforo = [],
        isAccionManual,
        isSelected,
        deleted,
        nuevo,
        isAnticipated,
      }: TableSubsumRow = data[rowIndex];
      const isRowResultado = rowType === 'resultado';

      return (
        <tr
          className={`custom-row ${
            isRowResultado
              ? isResultadoFinal
                ? 'custom-row-subsuma-final'
                : 'custom-row-subsuma'
              : ''
          } 
          ${deleted ? 'custom-row-deleted' : ''}
          ${nuevo ? 'custom-row-nuevo' : ''}
          ${isAnticipated ? 'custom-row-anticipated' : ''}
        `}
        >
          {tableRow.map((cell, idx) =>
            columnasVisibles[idx].options.viewColumns === false ||
            columnasVisibles[idx].options.display !== false ? (
              !isRowResultado ? (
                <td
                  key={`cell-${idx}`}
                  className={`custom-row-cell ${
                    (semaforo as Semaforo[]).some(
                      (s) => s.key === columnasVisibles[idx].name && s.isWarning
                    )
                      ? 'custom-row-cell-semaforo-warning'
                      : (semaforo as Semaforo[]).some(
                          (s) =>
                            s.key === columnasVisibles[idx].name && s.isDanger
                        )
                      ? 'custom-row-cell-semaforo-danger'
                      : ''
                  } custom-row-cell-centered-text`}
                >
                  {withSelect && idx === 0 ? (
                    isAccionManual === true ? (
                      <Chip label="Manual" variant="outlined" color="primary" />
                    ) : (
                      <Checkbox
                        value={isSelected}
                        onChange={() => onToggleRow(rowIndex)}
                        checked={isSelected === true}
                        color="primary"
                      />
                    )
                  ) : (
                    cell
                  )}
                </td>
              ) : idx !== 1 ? (
                <td
                  key={`cell-${idx}`}
                  className={`custom-row-cell ${
                    idx > 0 ? 'custom-row-cell-centered-text' : ''
                  }`}
                  style={{
                    fontSize:
                      columnasVisibles[idx].options.filter === true ? 0 : '',
                  }}
                  colSpan={idx === 0 ? 2 : 1}
                >
                  {cell}
                </td>
              ) : null
            ) : null
          )}
        </tr>
      );
    },
    [columnasVisibles, data, onToggleRow, withSelect]
  );

  return (
    <Table
      title={title}
      columns={columnasVisibles}
      data={data}
      onColumnViewChange={onColumnViewChange}
      options={{
        pagination: false,
        customRowRender: customRowRender,
        filter: false,
        ...tableOptions,
      }}
    />
  );
}

export default TableSubsum;

function renderExtraValues(value: any): string {
  return isNumber(value) ? redondearDosDecimalesToString(value) : value;
}

export function customBodyRenderNumberValue(value: any) {
  return Array.isArray(value)
    ? `${value[0] ? redondearDosDecimalesToString(value[0]) : ''}${
        value[0] ? ' ' : ''
      }${value[1] ? redondearDosDecimalesToString(value[1]) : ''}`
    : value
    ? redondearDosDecimalesToString(value)
    : '';
}

export function customBodyRenderValueTotalColumn(value: any, tableMeta: any) {
  const {
    rowType,
    isResultadoFinal,
    extra = [],
  } = tableMeta?.tableData[tableMeta?.rowIndex];
  return rowType === 'value' ? (
    value
  ) : (
    <div className="row-cell-informational">
      <span>{isResultadoFinal ? 'Total' : 'Subtotal'}</span>
      {Object.keys(extra).map((extraKey, idxExtra) => (
        <span key={`extra-${idxExtra}`}>
          {extra[extraKey]?.label}: {renderExtraValues(extra[extraKey]?.value)}
        </span>
      ))}
    </div>
  );
}

export function customBodyRenderDateTotalColumn(value: any, tableMeta: any) {
  const {
    rowType,
    isResultadoFinal,
    extra = [],
  } = tableMeta?.tableData[tableMeta?.rowIndex];
  return rowType === 'value' ? (
    customBodyRenderDate(value)
  ) : (
    <div className="row-cell-informational">
      <span>{isResultadoFinal ? 'Total' : 'Subtotal'}</span>
      {Object.keys(extra).map((extraKey, idxExtra) => (
        <span key={`extra-${idxExtra}`}>
          {extra[extraKey]?.label}: {renderExtraValues(extra[extraKey]?.value)}
        </span>
      ))}
    </div>
  );
}
export function customBodyRenderSelectedColumn(value: any, tableMeta: any) {
  const {
    rowType,
    isResultadoFinal,
    extra = [],
    isAnticipated,
  } = tableMeta?.tableData[tableMeta?.rowIndex] || {};
  return rowType === 'value' ? (
    value
  ) : (
    <div className="row-cell-informational">
      <span>
        {isResultadoFinal
          ? 'Total'
          : `Subtotal${isAnticipated ? ' (carga incompleta)' : ''}`}
      </span>
      {Object.keys(extra).map((extraKey, idxExtra) => (
        <span key={`extra-${idxExtra}`}>
          {extra[extraKey]?.label}: {renderExtraValues(extra[extraKey]?.value)}
        </span>
      ))}
    </div>
  );
}

export function customBodyRenderSummedAttribute(value: any, tableMeta: any) {
  return Array.isArray(value) ? (
    <div className="custom-row-cell-two-values">
      <span>{tableMeta?.columnData?.label}</span>
      <span>
        <span className="custom-row-cell-two-values-text custom-row-cell-two-values-text-positive">
          <ArrowDropUpIcon />
          {redondearDosDecimales(value[1] || 0)}
        </span>
        {value[0] !== 0 && (
          <Fragment>
            <span className="custom-row-cell-two-values-text"> /</span>
            <span className="custom-row-cell-two-values-text custom-row-cell-two-values-text-negative">
              <ArrowDropDownIcon />
              {redondearDosDecimales(value[0] || 0)}
            </span>
          </Fragment>
        )}
      </span>
    </div>
  ) : (
    redondearDosDecimales(value)
  );
}

export type Filters = { [key: string]: string[] };
type Row = { [key: string]: any };

export function shouldSumarCurrent<T extends Row>(
  dataElement: T,
  filters: Filters
) {
  return Object.keys(filters).every(
    (filterKey) =>
      filters[filterKey].length === 0 ||
      (dataElement[filterKey] &&
        filters[filterKey].includes(dataElement[filterKey]))
  );
}

export function filterByTableFilters<T extends Row>(
  data: T[],
  filters: Filters
): T[] {
  return data.filter((element) => shouldSumarCurrent(element, filters));
}

export function addFilterValuesToColumns(columns: any[], filters: Filters) {
  return columns.map((column) => ({
    ...column,
    filterOptions: {
      names: filters[column.name],
    },
  }));
}
