import moment from 'moment';

// Las queries son en horario local, pero tienen que mandarse en formato ISO
// toISOString convierte los horarios a utc primero, así que todos los horarios
// deben manejarse en local como si fueran utc (const now)

export function notUndefined<T>(val: T | undefined): val is T {
  return val !== undefined;
}

export const now = (value?: Date) => moment(value).utc().subtract(3, 'hour');
export const deNow = (utcedValue: string) => moment.utc(utcedValue).add(3, 'hour').toISOString();
export const today = now().endOf('day').toISOString();
export const todayStart = now().startOf('day').toISOString();
export const todayMinusSixHours = now().subtract(6, 'hours').toISOString();
export const yesterday = now().subtract(1, 'day').startOf('day').toISOString();
export const twoDaysAgo = now().subtract(2, 'day').startOf('day').toISOString();
export const threeDaysAgo = now().subtract(3, 'day').startOf('day').toISOString();
export const aMonthAgo = now().subtract(30, 'day').startOf('day').toISOString();

export const getEODFromDate = (date: Date) => {
  return moment(date).endOf('day').toISOString();
};
