export enum ROUTE_PATH {
  HOME = '/',
  CONFIGURACION = '/configuracion',
  SIP_REPORTES = '/reportes',
  PESADAS = '/pesadas',
  REPORTE_CARGAS = '/reporte-cargas',
  REPORTE_LOTES = '/reporte-lotes',
  REPORTE_MIXEROS = '/reporte-mixeros',
  MEZCLADO = '/mezclado',
  DESCARGAS = '/descargas',
  UBICACION = '/ubicacion',
  INGREDIENTES = '/ingredientes',
  LOTES = '/lotes',
  LECTURA_COMEDEROS = '/lectura-comederos',
  LECTURA_COMEDEROS_REMOTA = '/lectura-comederos-remota',
  CREADOR_DESCARGAS = '/creador-descargas',
  CREADOR_RECETAS = '/creador-recetas',
  HISTORICO_STOCK = '/stock-historico',
  HISTORICO_LOTES = '/lotes-historico',
  HISTORICO_INGREDIENTES = '/ingredientes-historico',
  REGISTRO_EVENTOS = '/registro-eventos',
  MENSAJES_CONTROL = '/mensajes-control',
  ITH = '/ith',
  HISTORICO_ITH = '/ith-historico',
  ITH_CONFIG_HORARIOS = '/ith-config-horarios',
  CMAS = '/cmas',
  HISTORICO_CMAS = '/cmas-historico',
  ADMIN_USUARIOS = '/admin-usuarios',
  GAC = '/gac',
  GAC_HISTORICO = '/gac-historico',
  GAC_REGISTRO_EVENTOS = '/gac-registro-eventos',
  GAC_ADMIN_USUARIOS = '/gac-admin-usuarios',
  GAC_ADMIN_VEHICULOS = '/gac-admin-vehiculos',
  CAS = '/cas',
  CAS_HISTORICO = '/cas-historico',
  AFIMILK = '/afimilk',
}
