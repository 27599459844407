import { DeviceEventBase } from '..';

export const ACTION_LOAD_BY_RECIPE = 'loadByRecipe';
export const ACTION_LOAD_MANUAL = 'manualLoad';
export const ACTION_DOWNLOAD_BY_GUIDE = 'downloadByGuide';
export const ACTION_DOWNLOAD_MANUAL = 'manualDownload';
export const ACTION_GENERAL_LOCATION = 'generalLocation';
export const ACTION_MIXING = 'mixing';
export const ACTION_FEEDER_STATE = 'feederState';
export const ACTION_CONNECTION_CONTROL = 'connectionControl';
export const ACTION_DEVICE_EVENT = 'event';
export const ACTION_AFIMILK = 'averageMilk';

export type DeviceDataEvent =
  | DeviceEventLoad
  | DeviceEventDownload
  | DeviceEventMixing
  | DeviceEventGeneralLocation
  | DeviceEventFeederState
  | DeviceEventAfimilk;

type DeviceDataEventActionType =
  | typeof ACTION_LOAD_MANUAL
  | typeof ACTION_LOAD_BY_RECIPE
  | typeof ACTION_DOWNLOAD_BY_GUIDE
  | typeof ACTION_DOWNLOAD_MANUAL
  | typeof ACTION_MIXING
  | typeof ACTION_GENERAL_LOCATION
  | typeof ACTION_FEEDER_STATE
  | typeof ACTION_CONNECTION_CONTROL
  | typeof ACTION_DEVICE_EVENT
  | typeof ACTION_AFIMILK;

export interface DeviceEventLoad extends DeviceEventBase {
  aCargar: number;
  action: typeof ACTION_LOAD_MANUAL | typeof ACTION_LOAD_BY_RECIPE;
  ingredient: string;
  toLoad: number;
  loaded: number;
  price: number;
  ms: number;
  riskIng: boolean;
  recipe: string;
  type: 'heads' | 'kg';
  amount: number;
  ingredientsQuantity: number;
  currentIngredient: number;
  endRecipe: 'yes' | 'yesAnticipated' | 'no';
  coordinates: string;
  addsToStock?: boolean;
}

export interface DeviceEventDownload extends DeviceEventBase {
  action: typeof ACTION_DOWNLOAD_BY_GUIDE | typeof ACTION_DOWNLOAD_MANUAL;
  lot: string;
  toDownload: number;
  downloaded: number;
  coordinates: string;
  downloadGuide: string;
  lotsQuantity: number;
  currentLot: number;
  lotKgMv?: number;
  endGuide: 'yes' | 'yesAnticipated' | 'no';
  elaborationPercentage: number;
  headsCount: number;
  kgHeads: number;
  correctionFactor: number;
}

export interface DeviceEventMixing extends DeviceEventBase {
  action: typeof ACTION_MIXING;
  serialNumber: string;
  mixingTime: string;
  missedMixing: boolean;
  missedMixingTime: string;
  mixedDisplacement: boolean;
  mixingStartCoordinates?: string;
  mixingEndCoordinates?: string;
}

export interface DeviceEventGeneralLocation extends DeviceEventBase {
  action: typeof ACTION_GENERAL_LOCATION;
  coordinates: string;
  speed: number;
  fuelCostPerLitre: number;
  averageFuelConsumptionPerKilometer: number;
}

export interface DeviceEventFeederState extends DeviceEventBase {
  action: typeof ACTION_FEEDER_STATE;
  coordinates: string;
  lot: string;
  state: string;
  value: number;
}

export interface DeviceEventControlMessage extends DeviceEventBase {
  action: typeof ACTION_CONNECTION_CONTROL;
  actualState: string;
  alarms: string;
  coordinates: string;
  creationTime: string;
  iUC: number;
  idLA: number;
  nAS: number;
  pAS: number;
  pASMC: number;
  idLAMC: number;
  nASMC: number;
  remoteScreen: boolean;
  signal: number;
  temp: number;
  ucState: boolean;
  vTractor: number;
}

export interface DeviceEventAfimilk extends DeviceEventBase {
  action: typeof ACTION_CONNECTION_CONTROL;
  data: {
    Indice: string;
    'Estado y patio': string;
    'Total de animales': string;
    DEL: string;
    'Ordeñadas si': string;
    'No Ordeñadas': string;
    'Producción Total': string;
    'Leche Últimas 24H': string;
    'Última semana': string;
    'Min vaca': string;
    'Min leche': string;
    'Max vaca': string;
    'Max leche': string;
  }[];
}

function filterAndSortBy(data: DeviceDataEvent[], actions: DeviceDataEventActionType[], mostRecentFirst?: boolean) {
  return data
    .filter((event) => (actions as string[]).includes(event.action))
    .sort(
      (element1, element2) =>
        (mostRecentFirst && element1.date > element2.date && -1) || (element1.date < element2.date && -1) || 1
    );
}

export function filterAndSortyByLoad(data: DeviceDataEvent[]): DeviceEventLoad[] {
  return filterAndSortBy(data, [ACTION_LOAD_BY_RECIPE, ACTION_LOAD_MANUAL]) as DeviceEventLoad[];
}

export function filterAndSortyByDownload(data: DeviceDataEvent[]): DeviceEventDownload[] {
  return filterAndSortBy(data, [ACTION_DOWNLOAD_BY_GUIDE, ACTION_DOWNLOAD_MANUAL]) as DeviceEventDownload[];
}

export function filterAndSortyByMixing(data: DeviceDataEvent[]): DeviceEventMixing[] {
  return filterAndSortBy(data, [ACTION_MIXING], true) as DeviceEventMixing[];
}

export function filterAndSortyByGeneralLocation(data: DeviceDataEvent[]): DeviceEventGeneralLocation[] {
  return (filterAndSortBy(data, [ACTION_GENERAL_LOCATION]) as DeviceEventGeneralLocation[]).filter(
    (event) => event.coordinates
  );
}

export function filterAndSortyByFeederState(data: DeviceDataEvent[]): DeviceEventFeederState[] {
  return filterAndSortBy(data, [ACTION_FEEDER_STATE], true) as DeviceEventFeederState[];
}


export function filterAndSortyByAfimilk(data: DeviceDataEvent[]): DeviceEventAfimilk[] {
  return filterAndSortBy(data, [ACTION_AFIMILK], true) as DeviceEventAfimilk[];
}

// Returns an array of unique action types on the data array
export function getReportedEventsActions(data: DeviceDataEvent[]) {
  return data.reduce((acc: string[], curr) => (!acc.includes(curr.action) ? [...acc, curr.action] : acc), []);
}

// Returns an array with all unique values in data corresponding to the filterkey
export function filterValuesInData<
  T extends DeviceEventBase,
  K extends keyof T //KeysWithValsOfType<T, string>
>(data: T[], filterKey: K): T[K][] {
  return data.reduce(
    (acc, curr) => (!!curr[filterKey] && !acc.includes(curr[filterKey]) ? acc.concat(curr[filterKey]) : acc),
    [] as T[K][]
  );
}
