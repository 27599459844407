import { customBodyRenderDateTotalColumn, customBodyRenderSummedAttribute } from 'components/table/TableSubsum';

// Solo mostrar el total en la última fila si hay un filtro por lote
export const customBodyRenderOnlyIfLotFilter = (value, tableMeta) => {
  if (tableMeta?.rowIndex === tableMeta?.currentTableData?.length - 1) {
    const lotFilter = tableMeta?.tableState?.filterList?.[2];
    if (lotFilter && lotFilter.length === 1) return customBodyRenderSummedAttribute(value, tableMeta);
    return;
  }

  return customBodyRenderSummedAttribute(value, tableMeta);
};

export const columnsHistoricoLotes = [
  {
    name: 'date',
    label: 'Fecha',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderDateTotalColumn,
    },
  },
  {
    name: 'downloadGuide',
    label: 'Guía Descarga',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'lot',
    label: 'Lote',
    options: { filter: true, sort: true },
  },
  {
    name: 'lotKgMv',
    label: 'Kg Lote MV Teorico',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'toDownload',
    label: 'Kg Descarga según Carga',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'downloaded',
    label: 'Kg Descargados',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'kgTeoricosMSGuia',
    label: 'Kg Teóricos MS Guía',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'kgDescargadosMS',
    label: 'Kg Descargados MS',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'porcentajeErrorCarga',
    label: '% Error según Carga',
    options: { filter: false, sort: false },
  },
  {
    name: 'user',
    label: 'Usuario',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'elaborationPercentage',
    label: '% Elaboración',
    options: { filter: false, sort: false },
  },
  {
    name: 'headsCount',
    label: 'Cabezas',
    options: { filter: false, sort: false },
  },
  {
    name: 'consumoRealCab',
    label: 'Consumo Real Cab MV',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderOnlyIfLotFilter,
    },
  },
  {
    name: 'consumoTeoricoCab',
    label: 'Consumo Teórico Cab MV',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderOnlyIfLotFilter,
    },
  },
  {
    name: 'porcentajeAlimentacionReal',
    label: '% Alimentación Real',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderOnlyIfLotFilter,
    },
  },
  {
    name: 'consumoTeoricoCabMs',
    label: 'Consumo Teórico Cab MS',
    options: { filter: false, sort: false,
      customBodyRender: customBodyRenderOnlyIfLotFilter, },
  },
  {
    name: 'consumoRealCabMs',
    label: 'Consumo Real Cab MS',
    options: { filter: false, sort: false,
      customBodyRender: customBodyRenderOnlyIfLotFilter, },
  },
  {
    name: 'priceConsumidosTotal',
    label: '$ Consumidos Total',
    options: { filter: false, sort: false, customBodyRender: customBodyRenderSummedAttribute },
  },
  {
    name: 'priceConsumidosCab',
    label: '$ Consumidos Cab',
    options: { filter: false, sort: false, customBodyRender: customBodyRenderSummedAttribute },
  },
];
