import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeviceItem, DEVICE_TYPE_SIP } from 'utils/device';
import { today, yesterday } from 'utils/helpers';
import type { RootState } from '../store';
import { api as authApi } from 'store/auth/authApi';
import { api } from './devicesApi';
import { toast } from 'react-toastify';
import { logout } from 'store/config/configSlice';
import parseDataCreadorDescargas from 'screens/creador-descargas/tabla-descarga/parser';
import { isDeviceVersionAtLeast, VERSION } from 'utils/helpers/validation';
import { getRecetaMVTotal } from 'screens/creador-descargas/tabla-descarga';
import { parseDescargas } from 'selectors/helpers';

type DeviceState = {
  deviceSelected: DeviceItem | null;
  date: string;
  minDate: string;
  maxDate: string;
};

const initialState: DeviceState = {
  deviceSelected: null,
  date: today, // (toISOString)
  minDate: yesterday,
  maxDate: today,
};

const slice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setDeviceSelected(state, action: PayloadAction<DeviceItem | null>) {
      if (action.payload?.serialNumber !== state.deviceSelected?.serialNumber) {
        state.deviceSelected = action.payload;

        // Si el dispositivo seleccionado es distinto al que estaba seleccionado, chequear las alarmas
        const descargas = parseDescargas(state.deviceSelected);
        if (descargas && descargas.length) {
          const isDeviceV2 = isDeviceVersionAtLeast(VERSION.TWO, state.deviceSelected);
          descargas.forEach((descarga) => {
            const descargaParsed = parseDataCreadorDescargas(
              {
                ...descarga,
                associatedRecipeData: state.deviceSelected?.recipes?.find(
                  (recipe) => recipe.id === descarga.associatedRecipe
                ),
              },
              isDeviceV2
            );

            const totalKgLoteMV = getRecetaMVTotal(descargaParsed) || 0;
            if (state.deviceSelected?.mixerMaxCapacity && totalKgLoteMV > state.deviceSelected?.mixerMaxCapacity)
              toast.error(`Exceso de carga en mixer para la descarga ${descarga.name}.`, { position: 'bottom-right' });
          });
        }
      }
    },
    setDate(state, action: PayloadAction<string>) {
      state.date = action.payload;
    },
    setMinDate(state, action: PayloadAction<string>) {
      state.minDate = action.payload;
    },
    setMaxDate(state, action: PayloadAction<string>) {
      state.maxDate = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Reset state on logout
    builder.addCase(logout, () => {
      return initialState;
    });
    builder.addMatcher(authApi.endpoints.getUser.matchFulfilled, (state, action) => {
      if (state.deviceSelected) {
        const deviceToSelect =
          action.payload.devices?.find((device) => device.serialNumber === state.deviceSelected?.serialNumber) || null;
        state.deviceSelected = deviceToSelect;
      }
    });
    builder.addMatcher(api.endpoints.getDeviceLatestControlMessages.matchFulfilled, (state, action) => {
      if (!action.payload?.length && state.deviceSelected?.type === DEVICE_TYPE_SIP)
        toast.warn('Balanza desconectada.', { position: 'bottom-right' });
    });
    builder.addMatcher(api.endpoints.getDeviceTasks.matchFulfilled, (state, action) => {
      if (action.payload?.length)
        toast.warn(`Quedan actualizaciones pendientes en el equipo. (${action.payload?.length})`, {
          position: 'bottom-right',
        });
    });
  },
});

export default slice.reducer;

export const selectDeviceSelected = (state: RootState): DeviceItem | null => state.devices.deviceSelected;
export const selectFechaSelected = (state: RootState) => state.devices.date;
export const selectMinDate = (state: RootState) => state.devices.minDate;
export const selectMaxDate = (state: RootState) => state.devices.maxDate;

export const selectDeviceSelectedSerialNumber = createSelector(selectDeviceSelected, (device) => device?.serialNumber);

export const selectDeviceSelectedType = createSelector(selectDeviceSelected, (device) => device?.type);

export const selectDeviceSelectedStock = createSelector(selectDeviceSelected, (device) => device?.sipnStock);

export const selectDeviceSelectedStockId = createSelector(selectDeviceSelected, (device) => device?.sipnStock?.id);

export const selectDeviceAssociatedAfimilk = createSelector(selectDeviceSelected, (device) => device?.associatedAfimilk);

export const selectDeviceSelectedStockIngredients = createSelector(
  selectDeviceSelected,
  (device) => device?.sipnStock?.ingredients
);

export const devicesActions = slice.actions;

export const { setDeviceSelected, setDate, setMinDate, setMaxDate } = slice.actions;
