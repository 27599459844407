import { customBodyRenderDate } from 'utils/helpers/body-renders';

export const columnsLecturaComederos = [
  {
    name: 'date',
    label: 'Fecha',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderDate,
    },
  },
  {
    name: 'lot',
    label: 'Lote',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'guide',
    label: 'Guía de Descarga',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'recipe',
    label: 'Receta',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'state',
    label: 'Estado',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'value',
    label: 'Valor',
    options: { filter: false, sort: false },
  },
  {
    name: 'user',
    label: 'Usuario',
    options: {
      filter: true,
      sort: false,
    },
  },
];
