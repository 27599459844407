import { useMemo } from 'react';

type Column = {
  name: string;
  label: string;
  options?: { customBodyRender?: (value: any, tableMeta?: any) => any };
  [key: string]: any;
};

export default function LiteTable({
  title = '',
  data = [],
  columns = [],
}: {
  title?: string;
  data: any[];
  columns: Column[];
}) {
  const rows = useMemo(
    () =>
      data.map((dataRow, rowIndex) =>
        columns.map(
          ({
            name,
            options: { customBodyRender = (value: any) => value } = {},
          }) => customBodyRender(dataRow[name], { tableData: data, rowIndex })
        )
      ),
    [columns, data]
  );

  return (
    <div className="lite-table-container">
      {!!title && <h4>{title}</h4>}
      <table>
        <tr>
          {columns.map(({ label }) => (
            <th>{label}</th>
          ))}
        </tr>
        {rows.map((row, idx) => (
          <tr
            className={`${
              data[idx].rowType === 'resultado'
                ? 'custom-row-subsuma-final'
                : ''
            }  ${data[idx].deleted ? 'custom-row-deleted' : ''}
            ${data[idx].nuevo ? 'custom-row-nuevo' : ''}`}
          >
            {row.map((value) => (
              <td>{value}</td>
            ))}
          </tr>
        ))}
      </table>
    </div>
  );
}
