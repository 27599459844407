import { columnsPesadas } from './pesadas';
import { columnsMezclado } from './mezclado';
import { columnsDescargas } from './descargas';
import { columnsStock } from './stock';
import { columnsLotes, columnsLotesV2 } from './lotes';
import { columnsStockHistorico } from './stock-historico';
import { columnsCmasHistorico } from './cmas-historico';
import { columnsITHHistorico } from './ith-historico';
import { columnsGACHistorico } from './gac-historico';
import { columnsTablaCreadorRecetas } from './tabla-receta';
import { columnsHistoricoIngredientes } from './historico-ingredientes';
import { columnsRegistroEventos } from './registro-eventos';
import { columnsTablaAlimentos } from './tabla-alimentos';
import { columnsMensajesControl } from './mensajes-control';
import { columnsLecturaComederos } from './lectura-comederos';
import { columnsHistoricoDietas } from './historico-dietas';
import { columnsHistoricoDescargas, columnsHistoricoDescargasV2 } from './historico-descargas';
import { columnsHistoricoLotes } from './historico-lotes';
import { columnsTablaCreadorDescargas, columnsTablaCreadorDescargasV2 } from './creador-descargas';
import { columnsAdminUsers } from './admin-users';
import { columnsAdminDevices } from './admin-devices';
import { columnsGacUsers } from './gac-users';
import { columnsGacVehicles } from './gac-vehicles';
import { columnsMensajesControlGac } from './mensajes-control-gac';
import { columnsCasHistorico } from './cas-historico';
import { columnsReporteCargas } from './reporte-cargas';
import { columnsAfimilk } from './afimilk'
import { columnsReporteLotes} from './reporte-lotes'

const tableColumns = {
  columnsDescargas,
  columnsMezclado,
  columnsPesadas,
  columnsStock,
  columnsLotes,
  columnsLotesV2,
  columnsCmasHistorico,
  columnsITHHistorico,
  columnsGACHistorico,
  columnsStockHistorico,
  columnsTablaAlimentos,
  columnsTablaCreadorRecetas,
  columnsHistoricoIngredientes,
  columnsRegistroEventos,
  columnsMensajesControl,
  columnsMensajesControlGac,
  columnsLecturaComederos,
  columnsHistoricoDietas,
  columnsHistoricoDescargas,
  columnsHistoricoDescargasV2,
  columnsHistoricoLotes,
  columnsTablaCreadorDescargas,
  columnsTablaCreadorDescargasV2,
  columnsAdminUsers,
  columnsAdminDevices,
  columnsGacUsers,
  columnsGacVehicles,
  columnsCasHistorico,
  columnsReporteCargas,
  columnsReporteLotes,
  columnsAfimilk
};

export default tableColumns;
