import {
  filterByTableFilters,
  Filters,
  RowExtraAttributes,
  Semaforo,
  TableSubsumaResultadoRow,
  TableSubsumaRowValue,
} from 'components/table/TableSubsum';
import moment from 'moment';
import { Recipe } from 'utils/device/event/diet';
import { DeviceEventDownload, filterValuesInData } from 'utils/device/event/sip';
import { UnloadGuide } from 'utils/device/lot';
import { Ingredient } from 'utils/device/stock';
import { redondearDosDecimales } from 'utils/helpers/math';
import parseDataRecetas from '../creador-recetas/tabla-receta/parser';

type AttributeToSum =
  | 'lotKgMv'
  | 'toDownload'
  | 'downloaded'
  | 'kgTeoricosMSGuia'
  | 'kgDescargadosMS'
  | 'porcentajeAlimentacionReal'
  | 'consumoRealCab'
  | 'consumoTeoricoCab'
  | 'consumoTeoricoCabMs'
  | 'consumoRealCabMs'
  | 'priceConsumidosTotal'
  | 'priceConsumidosCab';
type ExtraAttributes = any;
type FilterableAttributes = 'user' | 'downloadGuide' | 'lot';

export type DeviceEventDownloadSummed = DeviceEventDownload &
  TableSubsumaRowValue & {
    finReceta: boolean;
    semaforo: Semaforo[];
  };

type DeviceEventDownloadResultado = TableSubsumaResultadoRow<AttributeToSum, ExtraAttributes> & {
  [key in FilterableAttributes]: string[];
};

export type DeviceEventDownloadFormatted = DeviceEventDownloadSummed | DeviceEventDownloadResultado;

const attributesDescargasToSum: AttributeToSum[] = [
  'lotKgMv',
  'toDownload',
  'downloaded',
  'kgTeoricosMSGuia',
  'kgDescargadosMS',
  'porcentajeAlimentacionReal',
  'consumoRealCab',
  'consumoTeoricoCab',
  'consumoTeoricoCabMs',
  'consumoRealCabMs',
  'priceConsumidosTotal',
  'priceConsumidosCab',
];

function newAttributesToSumRow(isFinal: boolean = false): DeviceEventDownloadResultado {
  return {
    ...attributesDescargasToSum.reduce(
      (acc, curr): DeviceEventDownloadResultado => ({
        ...acc,
        [curr]: [0, 0],
      }),
      {
        rowType: 'resultado',
        isResultadoFinal: isFinal,
      } as DeviceEventDownloadResultado
    ),
    extra: {} as RowExtraAttributes<ExtraAttributes, string>,
  };
}

export default function parseDataDescargas(
  data: DeviceEventDownload[] = [],
  filters: Filters = {},
  descargas: UnloadGuide[],
  filterNotLoaded: boolean,
  recetas: Recipe[],
  ingredientes: Ingredient[] = []
): [DeviceEventDownloadFormatted[], { [key in FilterableAttributes]: DeviceEventDownloadFormatted[key][] }] {
  const [userValues, downloadGuideValues, lotValues] = [
    filterValuesInData(data, 'user'),
    filterValuesInData(data, 'downloadGuide'),
    filterValuesInData(data, 'lot'),
  ];

  const downloadData = filterByTableFilters(data, filters);

  const lotsElaborationPercentages = descargas.reduce(
    (acc, currDescarga) => ({
      ...acc,
      [currDescarga.name]: currDescarga.lots?.reduce(
        (acc, currLot) => ({
          ...acc,
          [currLot.name]: (currDescarga.lotsMetadata || {})[String(currLot.id)]?.elaborationPercentage,
        }),
        {}
      ),
    }),
    {} as Record<string, Record<string, number> | undefined>
  );

  let currTotal = newAttributesToSumRow(true);

  // Get the unique days in the data
  const days = [...new Set(downloadData.map((item) => moment.utc(item.date).format('DD-MM-YYYY')))];

  return [
    downloadData.reduce((acc, event, index): DeviceEventDownloadFormatted[] => {
      const { endGuide, downloadGuide, lotKgMv, toDownload, downloaded, lot, headsCount, kgHeads, correctionFactor } =
        event || {};
      let nextGuide = index + 1 < downloadData.length ? downloadData[index + 1].downloadGuide : '';
      const isFinReceta =
        endGuide === 'yes' || endGuide === 'yesAnticipated' || !!(downloadGuide && downloadGuide !== nextGuide);
      const porcentajeErrorCarga = redondearDosDecimales((downloaded * 100) / toDownload - 100);
      const elaborationPercentage = lotsElaborationPercentages[downloadGuide]?.[lot] || 100;
      const kgLotMVTeorico = redondearDosDecimales(((lotKgMv || 0) * elaborationPercentage) / 100);
      const consumoRealCab = redondearDosDecimales(downloaded / headsCount);
      const consumoTeoricoCab = redondearDosDecimales((kgHeads * correctionFactor * elaborationPercentage) / 100);
      const porcentajeAlimentacionReal = redondearDosDecimales((consumoRealCab * 100) / consumoTeoricoCab);
      const kgTeoricosMS = redondearDosDecimales(headsCount * kgHeads);
      const kgTeoricosMSGuia = redondearDosDecimales(kgTeoricosMS * (elaborationPercentage / 100));
      const kgDescargadosMS = redondearDosDecimales(downloaded / ((lotKgMv || 0) / kgTeoricosMS));
      const consumoRealCabMs = redondearDosDecimales(kgDescargadosMS / headsCount);
      const consumoTeoricoCabMs = redondearDosDecimales(kgHeads * (elaborationPercentage / 100));

      const recetaAsociada = recetas.find((receta) => receta?.name === downloadGuide);
      const recetaAsociadaParsed = recetaAsociada ? parseDataRecetas(recetaAsociada, ingredientes) : [];

      const recetaAsociadaPriceTotal =
        recetaAsociadaParsed.length > 0 ? recetaAsociadaParsed[recetaAsociadaParsed.length - 1].priceTotal?.[1] : 0;
      let recetaAsociadakgMV =
        recetaAsociadaParsed.length > 0 ? recetaAsociadaParsed[recetaAsociadaParsed.length - 1].kgMV : 0;
      if (Array.isArray(recetaAsociadakgMV)) recetaAsociadakgMV = recetaAsociadakgMV[1];

      const pricePorKgMV = redondearDosDecimales(recetaAsociadaPriceTotal / recetaAsociadakgMV) || 0;

      const priceConsumidosTotal = redondearDosDecimales(pricePorKgMV * downloaded);
      const priceConsumidosCab = redondearDosDecimales(priceConsumidosTotal / headsCount);

      const shouldAddRow = !filterNotLoaded || porcentajeErrorCarga > -95;

      const currRow: DeviceEventDownloadSummed = {
        ...event,
        lotKgMv: kgLotMVTeorico,
        finReceta: isFinReceta,
        porcentajeAlimentacionReal,
        porcentajeErrorCarga,
        kgTeoricosMS,
        kgTeoricosMSGuia,
        kgDescargadosMS,
        consumoRealCab,
        consumoTeoricoCab,
        consumoRealCabMs,
        consumoTeoricoCabMs,
        priceConsumidosTotal,
        priceConsumidosCab,
        rowType: 'value',
        semaforo: [
          {
            key: 'porcentajeErrorCarga',
            isWarning: Math.abs(porcentajeErrorCarga) > 5 && Math.abs(porcentajeErrorCarga) < 15,
            isDanger: Math.abs(porcentajeErrorCarga) >= 15,
          },
        ],
      };

      currTotal = shouldAddRow ? sumRowAttributes(currTotal, currRow) : currTotal;

      if (index === downloadData.length - 1) {
        currTotal.consumoRealCab = [
          currTotal.consumoRealCab[0] / days.length,
          currTotal.consumoRealCab[1] / days.length,
        ];
        currTotal.consumoTeoricoCab = [
          currTotal.consumoTeoricoCab[0] / days.length,
          currTotal.consumoTeoricoCab[1] / days.length,
        ];
        currTotal.porcentajeAlimentacionReal = [
          (currTotal.consumoRealCab[0] * 100) / currTotal.consumoTeoricoCab[0] || 0,
          (currTotal.consumoRealCab[1] * 100) / currTotal.consumoTeoricoCab[1] || 0,
        ];
        currTotal.consumoRealCabMs = [
          currTotal.consumoRealCabMs[0] / days.length,
          currTotal.consumoRealCabMs[1] / days.length,
        ];
        currTotal.consumoTeoricoCabMs = [
          currTotal.consumoTeoricoCabMs[0] / days.length,
          currTotal.consumoTeoricoCabMs[1] / days.length,
        ];
      }

      return [
        ...acc,
        ...(shouldAddRow ? [currRow] : []),
        ...(index === downloadData.length - 1
          ? [
              {
                ...currTotal,
                user: userValues,
                downloadGuide: downloadGuideValues,
                lot: lotValues,
              },
            ]
          : []),
      ];
    }, [] as DeviceEventDownloadFormatted[]),
    { user: userValues, downloadGuide: downloadGuideValues, lot: lotValues },
  ];
}

function sumRowAttributes(
  currSubsuma: DeviceEventDownloadResultado,
  row: DeviceEventDownloadSummed
): DeviceEventDownloadResultado {
  let newCurrSubsuma = { ...currSubsuma };
  attributesDescargasToSum.forEach((key) => {
    if (row[key])
      if (row[key] >= 0) newCurrSubsuma[key][1] = redondearDosDecimales(newCurrSubsuma[key][1] + row[key]);
      else newCurrSubsuma[key][0] = redondearDosDecimales(newCurrSubsuma[key][0] + row[key]);
  });
  return newCurrSubsuma;
}
