import Input from 'components/input';
import { ChipInfo } from 'design-system/chip/chip-info';
import Select from 'design-system/select';
import { TableRowTwoCols } from 'design-system/table/table-row';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { descargasActions, selectDescargaEditing } from 'store/descargas/descargasSlice';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import { UnloadGuide } from 'utils/device/lot';
import { isDeviceVersionAtLeast, VERSION } from 'utils/helpers/validation';

const TIPO_DESCARGA_OPTIONS = [
  {
    name: 'Seleccion Manual',
    value: 'manual',
  },
  {
    name: 'Automatica',
    value: 'automatica',
  },
  {
    name: 'Sin especificar',
    value: 'Sin especificar',
  },
];

const SOBRANTE_OPTIONS = [
  {
    name: 'Si',
    value: 'si',
  },
  {
    name: 'No',
    value: 'no',
  },
];

const TURNO_OPTIONS = [
  {
    name: 'Mañana',
    value: 'mañana',
  },
  {
    name: 'Tarde',
    value: 'tarde',
  },
  {
    name: 'Noche',
    value: 'noche',
  },
  {
    name: 'Sin especificar',
    value: 'Sin especificar',
  },
];

const DescargaForm = ({ descarga, editingEnabled }: { descarga?: UnloadGuide; editingEnabled?: boolean }) =>
  editingEnabled ? <DescargaFormEditable /> : descarga ? <DescargaFormBase descarga={descarga} /> : null;
export default DescargaForm;

function DescargaFormEditable() {
  const descargaEditing = useSelector(selectDescargaEditing);
  return <DescargaFormBase descarga={descargaEditing} editingEnabled />;
}

function DescargaFormBase({ descarga, editingEnabled }: { descarga: UnloadGuide; editingEnabled?: boolean }) {
  const dispatch = useDispatch();
  const deviceSelected = useSelector(selectDeviceSelected);
  const isDeviceVersionTwo = isDeviceVersionAtLeast(VERSION.TWO, deviceSelected);
  const isDeviceVersionFive = isDeviceVersionAtLeast(VERSION.FIVE, deviceSelected);

  const { id, name = '', associatedRecipe, unloadType = '', unloadSchedule = '', leftoverFood = 'no' } = descarga || {};

  const onNombreChange = (value: string) => {
    dispatch(descargasActions.onDescargaFormChange({ ...descarga, name: value }));
  };

  const onRecetaAsociadaChange = (value: string) => {
    dispatch(descargasActions.onDescargaFormChange({ ...descarga, associatedRecipe: Number(value) }));
  };

  const onTipoDescargaChange = (value: string) => {
    dispatch(descargasActions.onDescargaFormChange({ ...descarga, unloadType: value }));
  };

  const onSobranteChange = (value: string) => {
    dispatch(descargasActions.onDescargaFormChange({ ...descarga, leftoverFood: value }));
  };

  const onTurnoChange = (value: string) => {
    dispatch(descargasActions.onDescargaFormChange({ ...descarga, unloadSchedule: value }));
  };

  const RECIPE_OPTIONS = useMemo(
    () =>
      (deviceSelected?.recipes || []).map((recipe) => ({
        name: recipe.name,
        value: `${recipe.id}`,
      })),
    [deviceSelected?.recipes]
  );

  const showSelectID = !!id;

  return (
    <div className="flex flex-col gap-2">
      <TableRowTwoCols>
        <div className={!showSelectID ? 'w-full mobile:col-span-2' : 'w-full'}>
          <Input
            label={'Nombre'}
            placeholder="Nombre"
            defaultValue={name || ''}
            onBlur={onNombreChange}
            disabled={!editingEnabled}
            maxLength={isDeviceVersionFive ? 25 : 14}
          />
        </div>
        {showSelectID && (
          <div className="mb-1 w-full">
            <ChipInfo>ID de descarga {id}</ChipInfo>
          </div>
        )}
      </TableRowTwoCols>
      <TableRowTwoCols>
        {isDeviceVersionTwo && (
          <Select
            name="Receta asociada"
            label="Receta asociada"
            placeholder="Sin receta asociada"
            placeholderEnabled
            options={RECIPE_OPTIONS}
            onChangeValue={(value: string) => {
              onRecetaAsociadaChange(value);
            }}
            value={`${associatedRecipe || 0}`}
            disabled={!editingEnabled}
          />
        )}
        {isDeviceVersionFive && (
          <Select
            name="Tipo de Descarga"
            label="Tipo de Descarga"
            options={TIPO_DESCARGA_OPTIONS}
            onChangeValue={(value: string) => {
              onTipoDescargaChange(value);
            }}
            value={`${unloadType || 'Sin Especificar'}`}
            disabled={!editingEnabled}
          />
        )}
      </TableRowTwoCols>
      <TableRowTwoCols>
        {isDeviceVersionFive && (
          <Select
            name="Sobrante"
            label="Sobrante"
            options={SOBRANTE_OPTIONS}
            onChangeValue={(value: string) => {
              onSobranteChange(value);
            }}
            value={`${leftoverFood || ''}`}
            disabled={!editingEnabled}
          />
        )}
        {isDeviceVersionFive && (
          <Select
            name="Turno"
            label="Turno"
            options={TURNO_OPTIONS}
            onChangeValue={(value: string) => {
              onTurnoChange(value);
            }}
            value={`${unloadSchedule || 'Sin Especificar'}`}
            disabled={!editingEnabled}
          />
        )}
      </TableRowTwoCols>
    </div>
  );
}
