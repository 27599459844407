import { FullScreenLoader } from 'components/loader';
import FormCard from 'design-system/form-card';
import SidebarSlide from 'design-system/sidebar-slide';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDeviceSelectedSerialNumber } from 'store/devices/devicesSlice';
import { useAddRecipeMutation, useUpdateRecetaMutation } from 'store/recetas/recetasApi';
import { recetasActions, selectRecetaEditing } from 'store/recetas/recetasSlice';
import { Recipe } from 'utils/device/event/diet';
import TablaReceta from '../tabla-receta';
import RecetaForm from './receta-form';
import TablaAlimentos from './tabla-alimentos';

function ModalAgregarReceta({
  isOpen,
  onClose = () => {},
  setIsSavingData = () => {},
}: {
  isOpen: boolean;
  onClose: () => void;
  setIsSavingData?: (isSavingData: boolean) => void;
}) {
  const dispatch = useDispatch();

  const recetaEditing = useSelector(selectRecetaEditing);
  const serialNumber = useSelector(selectDeviceSelectedSerialNumber) || '';

  const [onAddRecipe, { isLoading: isAddRecipeLoading }] = useAddRecipeMutation();

  const [onUpdateReceta, { isLoading: isUpdateRecetaLoading }] = useUpdateRecetaMutation();

  const { name = '' } = recetaEditing || {};
  const canGuardar = name !== '';

  const handleClose = () => {
    dispatch(recetasActions.resetReceta());
    onClose();
  };

  const onSave = () => {
    setIsSavingData(true);
    if (!recetaNombre)
      onAddRecipe({
        receta: parseRecetaBeforeSending(recetaEditing),
        serialNumber,
      });
    else
      onUpdateReceta({
        receta: parseRecetaBeforeSending(recetaEditing),
        serialNumber,
      });
    handleClose();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const recetaNombre = useMemo(() => recetaEditing.name, [isOpen]); // deshabilitar warning porque es para que no se cambie el titulo del modal con cada cambio del nombre de la receta

  return (
    <SidebarSlide
      isOpen={isOpen}
      onClose={handleClose}
      title={!recetaNombre ? 'Nueva receta' : `Editar "${recetaNombre}"`}
      actions={[
        {
          autoFocus: false,
          onClick: handleClose,
          text: 'Cancelar',
          variant: 'text-danger',
        },
        {
          autoFocus: false,
          onClick: onSave,
          text: 'Guardar',
          variant: 'fill',
          disabled: !canGuardar,
        },
      ]}
    >
      {(isUpdateRecetaLoading || isAddRecipeLoading) && <FullScreenLoader />}
      <FormCard
        items={[
          {
            value: <RecetaForm editingEnabled />,
          },
          {
            value: <TablaAlimentos />,
          },
          {
            value: <TablaReceta receta={recetaEditing} editingEnabled />,
          },
        ]}
      />
    </SidebarSlide>
  );
}
export default ModalAgregarReceta;

function parseRecetaBeforeSending(receta: Recipe): Recipe {
  let recetaToSend = {
    ...receta,
    mixingTime: receta.mixingTime || 0,
    realHeadCount: receta.realHeadCount || 0,
    ingredients: receta.ingredients
      .filter((ingredient) => !ingredient.deleted)
      .map(({ nuevo, ...ingrediente }) => ({ ...ingrediente })),
  };
  if (receta.unloadGuide) {
    const { lots, ...guide } = receta.unloadGuide;
    recetaToSend.unloadGuide = { ...guide };
  }
  return recetaToSend;
}
