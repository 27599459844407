import AlertDialog from 'components/alert-dialog';
import { FullScreenLoader } from 'components/loader';
import SpaceDivider from 'components/space-divider';
import TareasPendientesBanner from 'components/tareas-pendientes-banner';
import { ChipQuestionMark } from 'design-system/chip/chip-question-mark';
import FormCard, { FormCardIcon } from 'design-system/form-card';
import ViewHeader from 'design-system/view-header';
import { useModal } from 'hooks/useModal';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDescargas } from 'selectors/descargas.selectors';
import { useDeleteUnloadGuideMutation } from 'store/descargas/descargasApi';
import { editDescarga, resetDescarga } from 'store/descargas/descargasSlice';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import { UnloadGuide } from 'utils/device/lot';
import ModalAgregarDescarga from './modal-agregar-descarga';
import DescargaForm from './modal-agregar-descarga/descarga-form';
import TablaDescarga from './tabla-descarga';

function CreadorDescargas() {
  const dispatch = useDispatch();
  const [onDeleteUnloadGuide, { isLoading: isDeleteUnloadGuideLoading }] = useDeleteUnloadGuideMutation();

  const deviceSelected = useSelector(selectDeviceSelected);
  const descargas = useSelector(selectDescargas);
  const { serialNumber = '' } = deviceSelected || {};
  const [isOpenAgregarDescarga, onOpenAgregarDescarga, onCloseAgregarDescarga] = useModal();
  const [isOpenEliminarDescarga, onOpenEliminarDescarga, onCloseEliminarDescarga, descargaToEliminar] =
    useModal<UnloadGuide>();

  // Quick fix para resetear la UX de la receta cuando se cierra el modal
  const [isSavingData, setIsSavingData] = useState(false);

  const handleOpenAgregarDescarga = (descarga?: UnloadGuide) => {
    if (!descarga) dispatch(resetDescarga());
    else dispatch(editDescarga(descarga));
    onOpenAgregarDescarga(null);
  };

  const handleEliminarDescarga = () => {
    if (descargaToEliminar !== null) onDeleteUnloadGuide({ serialNumber, descarga: descargaToEliminar });
    onCloseEliminarDescarga();
  };

  useEffect(() => {
    setIsSavingData(false);
  }, [descargas]);

  return (
    <>
      {(isDeleteUnloadGuideLoading || isSavingData) && <FullScreenLoader />}
      {isOpenAgregarDescarga && (
        <ModalAgregarDescarga
          isOpen={isOpenAgregarDescarga}
          onClose={onCloseAgregarDescarga}
          setIsSavingData={setIsSavingData}
        />
      )}
      <AlertDialog
        isOpen={isOpenEliminarDescarga}
        onClose={onCloseEliminarDescarga}
        title="Eliminar Descarga"
        onPositiveClick={handleEliminarDescarga}
        positiveLabel="Eliminar"
      >
        ¿Está seguro que desea eliminar la descarga? Esta acción no se puede deshacer.
      </AlertDialog>
      <ViewHeader
        title="Creador de Descargas"
        action={{
          label: 'Crear',
          onClick: () => handleOpenAgregarDescarga(),
          isProtected: true,
        }}
      />
      <div className="content-view px-8">
        <TareasPendientesBanner />
        <SpaceDivider />
        {descargas.length === 0 ? (
          <ChipQuestionMark>No se encontraron descargas para este equipo.</ChipQuestionMark>
        ) : (
          descargas.map((descarga, index) => (
            <Fragment key={serialNumber + descarga.id + String(isSavingData) + index}>
              <FormCard
                title={descarga.name}
                icon={<FormCardIcon>{descarga.id}</FormCardIcon>}
                onCancel={() => onOpenEliminarDescarga(descarga)}
                onSave={() => handleOpenAgregarDescarga(descarga)}
                isCancelProtected
                isSaveProtected
                cancelLabel="Eliminar"
                saveLabel="Modificar"
                items={[
                  { value: <DescargaForm descarga={descarga} /> },
                  { value: <TablaDescarga descarga={descarga} lite /> },
                ]}
              />
              <SpaceDivider className="double" />
            </Fragment>
          ))
        )}
      </div>
    </>
  );
}

export default CreadorDescargas;
