import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import { HAASTEN_LIGHT } from 'components/colors';
import { useState } from 'react';
import { Brush, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { CustomTooltip } from 'screens/ith-historico/ITHPromedioCharts';
import { COLORS } from 'utils/constants/colors';

type ChartDataPoint = {
  date: string;
  name: string;
  value: number;
  [key: string]: any;
};

const KEYS = [
  {
    key: 'lotKgMv',
    name: 'Kg Lote MV Teorico',
  },
  {
    key: 'toDownload',
    name: 'Kg Descarga según Carga',
  },
  {
    key: 'downloaded',
    name: 'Kg Descargados',
  },
  {
    key: 'kgTeoricosMSGuia',
    name: 'Kg Teóricos MS Guía',
  },
  {
    key: 'kgDescargadosMS',
    name: 'Kg Descargados MS',
  },
];

export function ReporteLotesGrafico({ data }: { data: ChartDataPoint[] }) {
  const [activeKeys, setActiveKeys] = useState<string[]>(KEYS.map((k) => k.key));

  return (
    <div className="flex flex-col h-full w-full gap-4">
      <div className="w-full flex items-center justify-center gap-2 px-8">
        {KEYS.map((key, index) => {
          const isActive = activeKeys.includes(key.key);
          return (
            <div key={key.key} className="flex items-center gap-1">
              <div
                className={clsx(
                  'flex items-center gap-2 p-2 rounded-lg underline underline-offset-8 transition-all duration-150 cursor-pointer hover:bg-white/10',
                  isActive ? `decoration-2` : 'decoration-0 hover:decoration-2 opacity-50 hover:opacity-100'
                )}
                style={{ textDecorationColor: isActive ? COLORS[index] : 'transparent' }}
                onClick={() => {
                  setActiveKeys(isActive ? activeKeys.filter((k) => k !== key.key) : [...activeKeys, key.key]);
                }}
              >
                <div
                  className="w-6 h-6 flex items-center justify-center border rounded-lg"
                  style={{
                    borderColor: COLORS[index],
                  }}
                >
                  {isActive && <CheckIcon fontSize='small' />}
                </div>
                {key.name}
              </div>
            </div>
          );
        })}
      </div>

      <div className="w-full h-96">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data}>
            {KEYS.map((key, index) => (
              <Line
                key={key.key}
                type="monotone"
                yAxisId="left"
                dataKey={key.key}
                name={key.name}
                stroke={COLORS[index]}
                opacity={activeKeys.includes(key.key) ? 1 : 0}
              />
            ))}
            <CartesianGrid stroke="#ccc" strokeDasharray="3 3" opacity={0.5} />
            <XAxis dataKey="date" />
            <YAxis yAxisId="left" orientation="left" />
            <Tooltip content={<CustomTooltip />} />
            <Brush dataKey="date" height={30} stroke={HAASTEN_LIGHT} fill={'rgba(0,0,0,0)'} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
