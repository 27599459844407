import { useSelector } from 'react-redux';
import {
  selectDeviceAssociatedAfimilk,
  selectDeviceSelectedSerialNumber,
  selectDeviceSelectedStockId,
  selectMaxDate,
  selectMinDate,
} from 'store/devices/devicesSlice';

export function useCommonQueryParameters() {
  const serialNumber = useSelector(selectDeviceSelectedSerialNumber) || '';
  const selectedDeviceStockId = useSelector(selectDeviceSelectedStockId) || '';
  const selectDeviceAfimilk = useSelector(selectDeviceAssociatedAfimilk) || '';
  const minDate = useSelector(selectMinDate);
  const maxDate = useSelector(selectMaxDate);

  return { serialNumber, selectedDeviceStockId, selectDeviceAfimilk, minDate, maxDate };
}
