import { useMemo } from 'react';
import MUIDataTable from 'mui-datatables';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  DEFAULT_FONT_COLOR,
  DEFAULT_BACKGROUND,
  BORDER_COLOR,
  HOVER_COLOR,
  OPACITY_LEVEL,
  HAASTEN_LIGHT,
} from '../colors';

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiTable: {
        root: {
          borderCollapse: 'initial',
        },
      },
      MuiPaper: {
        root: {
          overflow: 'auto',
          color: DEFAULT_FONT_COLOR,
          backgroundColor: 'transparent',
        },
      },
      MuiIconButton: {
        root: {
          color: DEFAULT_FONT_COLOR,
          backgroundColor: OPACITY_LEVEL,
          borderRadius: 0,
          margin: 0,
          // marginLeft: ,
        },
      },
      MUIDataTableToolbar: {
        root: {
          padding: 0,
        },
        titleText: {
          margin: '0 !important',
        },
        iconActive: {
          color: DEFAULT_FONT_COLOR,
          backgroundColor: HOVER_COLOR,
          '&:hover': {
            opacity: 0.8,
          },
        },
        icon: {
          '&:hover': {
            opacity: 0.8,
          },
        },
        actions: {
          padding: '8px',
        },
      },
      MUIDataTableSearch: {
        searchText: {
          backgroundColor: 'transparent',
        },
        searchIcon: {
          color: DEFAULT_FONT_COLOR,
        },
      },
      MuiInputBase: {
        root: {
          color: DEFAULT_FONT_COLOR,
        },
      },
      MuiInput: {
        underline: {
          '&:before': {
            borderBottom: `1px solid ${DEFAULT_FONT_COLOR}`,
          },
          '&:after': {
            borderBottom: `2px solid ${DEFAULT_FONT_COLOR}`,
          },
          'input:focus': {
            border: 'none',
          },
          '&& input': {
            border: 'none',
          },
        },
      },
      MUIDataTableBodyRow: {
        root: {
          '&:hover': {
            opacity: 0.8,
          },
          '&:not(:last-child)': {
            '&& td': {
              borderBottom: `1px solid ${BORDER_COLOR}`,
            },
          },
        },
      },
      MuiTableCell: {
        head: {
          whiteSpace: 'nowrap',
          textAlign: 'left',
        },
        root: {
          borderBottom: `none`,
          backgroundColor: 'transparent',
        },
        body: {
          color: DEFAULT_FONT_COLOR,
          whiteSpace: 'nowrap',
        },
      },
      MUIDataTableHeadRow: {
        root: {
          position: 'sticky',
          top: 0,
          backgroundColor: OPACITY_LEVEL,
        },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: 'transparent',
          color: DEFAULT_FONT_COLOR,
        },
        data: {
          backgroundColor: 'transparent',
          color: DEFAULT_FONT_COLOR,
        },
      },
      MUIDataTableSelectCell: {
        headerCell: {
          backgroundColor: 'transparent',
        },
      },
      MuiFormControl: {
        root: {
          backgroundColor: DEFAULT_BACKGROUND,
        },
      },
      MuiCheckbox: {
        root: {
          color: HAASTEN_LIGHT,
        },
      },
      MUIDataTableFilter: {
        root: {
          backgroundColor: DEFAULT_BACKGROUND,
        },
        checkboxFormControlLabel: {
          color: HAASTEN_LIGHT,
        },
        checkboxListTitle: {
          color: HAASTEN_LIGHT,
        },
        title: {
          color: DEFAULT_FONT_COLOR,
        },
      },
      MUIDataTableViewCol: {
        title: {
          color: DEFAULT_FONT_COLOR,
        },
        label: {
          color: DEFAULT_FONT_COLOR,
        },
      },
    },
  });

export default function Table({
  title = '',
  data,
  columns,
  options = {},
  rowsSelected = [],
  onColumnViewChange,
}: TableProps) {
  let tableOptions = useMemo(() => {
    let opt = {
      ...defaultTableOptions(),
      ...options,
      rowsSelected,
      onColumnViewChange: (changedColumn: any, action: string) => {
        const newColumnasVisibles = columns.map((columna) => {
          const newCol = { ...columna, options: { ...columna.options } };
          if (columna.name === changedColumn)
            newCol.options.display = action === 'add';
          return newCol;
        });
        if (onColumnViewChange) onColumnViewChange(newColumnasVisibles);
      },
    };
    return opt;
  }, [columns, onColumnViewChange, options, rowsSelected]);

  // actualizando la key se puede rerenderizar la tabla, cambiando las options no se actualiza
  const key = `datatable-${title}-${
    Array.isArray(rowsSelected) ? rowsSelected.length : 1
  }`;

  return (
    <div className="table-container">
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          key={key}
          title={title}
          data={data}
          columns={columns}
          options={tableOptions}
        />
      </MuiThemeProvider>
    </div>
  );
}

export interface TableProps {
  title?: string;
  data: any[];
  columns: any[];
  rowsSelected?: any[];
  options?: any;
  onColumnViewChange?: (columnas: any) => void;
}

function defaultTableOptions() {
  return {
    filterType: 'checkbox',
    selectableRows: 'none',
    rowsPerPage: 20,
    responsive: 'standard',
    fixedHeaderOptions: { xAxis: false, yAxis: false },
    textLabels: {
      body: {
        noMatch: 'No se encontraron datos.',
        toolTip: 'Ordenar',
        columnHeaderTooltip: (column: any) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: 'Página siguiente',
        previous: 'Página anterior',
        rowsPerPage: 'Filas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver columnas',
        filterTable: 'Filtrar tabla',
      },
      filter: {
        all: 'Todo',
        title: 'FILTROS',
        reset: 'RESETEAR',
      },
      viewColumns: {
        title: 'Mostrar columnas',
        titleAria: 'Mostrar/ocultar columnas',
      },
      selectedRows: {
        text: 'fila(s) seleccionadas',
        delete: 'Eliminar',
        deleteAria: 'Eliminar filas seleccionadas',
      },
    },
  };
}
