import Card from 'design-system/card';
import { ReactNode } from 'react';

export default function Table({ title, subtitle, rows, Icon }: TableProps) {
  return (
    <Card title={title} subtitle={subtitle} icon={Icon}>
      {rows?.map((RowComponent, index) => (
        <div
          className={`${
            index & 1 ? 'bg-background-black' : 'bg-neutral-900'
          } items-center`}
        >
          <dd className={`text-sm`}>{RowComponent}</dd>
        </div>
      ))}
    </Card>
  );
}

interface TableProps {
  title: string;
  subtitle?: string;
  rows: ReactNode[];
  Icon?: ReactNode;
}
