import { FullScreenLoader } from 'components/loader';
import TableView from 'design-system/table-view';
import ViewHeader from 'design-system/view-header';
import { useCalendarQuery } from 'hooks/useCalendarQuery';
import { useColumns } from 'hooks/useColumns';
import { useGetDeviceControlMessagesQuery } from 'store/devices/devicesApi';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import { useSelector } from 'react-redux';
import { isDeviceGac } from 'utils/device';

function MensajesControl() {
  const deviceSelected = useSelector(selectDeviceSelected);

  const { data: deviceControlMessages = [], isFetching: isDeviceControlMessagesFetching } = useCalendarQuery(
    useGetDeviceControlMessagesQuery
  );

  const COLUMNS_ID =
    deviceSelected && isDeviceGac(deviceSelected) ? 'columnsMensajesControlGac' : 'columnsMensajesControl';

  const { columns, onColumnsViewChange } = useColumns(COLUMNS_ID);

  return (
    <>
      {isDeviceControlMessagesFetching && <FullScreenLoader />}
      <ViewHeader title="Mensajes de Control" dateSelector="range" />
      <TableView
        data={deviceControlMessages}
        columns={columns}
        options={{
          pagination: false,
          filter: false,
        }}
        onColumnViewChange={onColumnsViewChange}
      />
    </>
  );
}

export default MensajesControl;
