import { Checkbox } from '@material-ui/core';
import { HAASTEN_LIGHT } from 'components/colors';

const ChipToggle = ({
  label,
  onToggle,
  toggleValue,
  noBorder,
}: {
  label: string;
  onToggle: (value: boolean) => void;
  toggleValue: boolean;
  noBorder?: boolean;
}) => {
  return (
    <div
      className={`flex items-center cursor-pointer select-none border rounded-md border-haasten-light w-full ${
        noBorder ? 'border-none' : ''
      }`}
      onClick={() => {
        onToggle(!toggleValue);
      }}
    >
      <Checkbox
        onChange={(event) => onToggle(event.target.checked)}
        checked={toggleValue === true}
        value={toggleValue}
        style={{
          color: HAASTEN_LIGHT,
        }}
      />
      <span className="pr-2">{label}</span>
    </div>
  );
};

export default ChipToggle;
