import { FullScreenLoader } from 'components/loader';
import FormCard from 'design-system/form-card';
import SidebarSlide from 'design-system/sidebar-slide';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAddUnloadGuideMutation, useUpdateUnloadGuideMutation } from 'store/descargas/descargasApi';
import { resetDescarga, selectDescargaEditing } from 'store/descargas/descargasSlice';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import { UnloadGuide } from 'utils/device/lot';
import TablaDescarga from '../tabla-descarga';
import DescargaForm from './descarga-form';
import TablaLotes from './tabla-lotes';

function ModalAgregarDescarga({
  isOpen,
  onClose = () => {},
  setIsSavingData = () => {},
}: {
  isOpen: boolean;
  onClose: () => void;
  setIsSavingData?: (isSavingData: boolean) => void;
}) {
  const dispatch = useDispatch();
  const [onAddUnloadGuide, { isLoading: isAddUnloadGuideLoading }] = useAddUnloadGuideMutation();
  const [onUpdateUnloadGuide, { isLoading: isUpdateUnloadGuideLoading }] = useUpdateUnloadGuideMutation();

  const deviceSelected = useSelector(selectDeviceSelected);
  const descargaEditing = useSelector(selectDescargaEditing);
  const { name = '' } = descargaEditing || {};
  const canGuardar = name !== '';

  const handleClose = () => {
    dispatch(resetDescarga());
    onClose();
  };

  const onSave = () => {
    setIsSavingData(true);
    if (!descargaNombre)
      onAddUnloadGuide({
        descarga: parseDescargaBeforeSending(descargaEditing),
        serialNumber: deviceSelected?.serialNumber || '',
      });
    else
      onUpdateUnloadGuide({
        descarga: parseDescargaBeforeSending(descargaEditing),
        serialNumber: deviceSelected?.serialNumber || '',
      });
    handleClose();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const descargaNombre = useMemo(() => descargaEditing.name, [isOpen]); // deshabilitar warning porque es para que no se cambie el titulo del modal con cada cambio del nombre de la descarga

  return (
    <SidebarSlide
      isOpen={isOpen}
      onClose={handleClose}
      title={!descargaNombre ? 'Nueva descarga' : `Editar "${descargaNombre}"`}
      actions={[
        {
          autoFocus: false,
          onClick: handleClose,
          text: 'Cancelar',
          variant: 'text-danger',
        },
        {
          autoFocus: false,
          onClick: onSave,
          text: 'Guardar',
          variant: 'fill',
          disabled: !canGuardar,
        },
      ]}
    >
      {(isAddUnloadGuideLoading || isUpdateUnloadGuideLoading) && <FullScreenLoader />}
      <FormCard
        items={[
          {
            value: <DescargaForm editingEnabled />,
          },
          {
            value: <TablaLotes />,
          },
          {
            value: <TablaDescarga descarga={descargaEditing} editable />,
          },
        ]}
      />
    </SidebarSlide>
  );
}
export default ModalAgregarDescarga;

function parseDescargaBeforeSending({ lotIds, lots = [], ...values }: UnloadGuide): UnloadGuide {
  const missingElaborationPercentage =
    lots.length > Object.keys(values?.lotsMetadata || {}).length
      ? lots
          .filter(({ id }) => !(values.lotsMetadata || {})[id])
          .reduce(
            (acc, curr) => ({
              ...acc,
              [curr.id]: { elaborationPercentage: 100 },
            }),
            {}
          )
      : [];
  return {
    ...values,
    lotsMetadata: {
      ...values.lotsMetadata,
      ...missingElaborationPercentage,
    },
    lotIds: lots.filter((lot) => !lot.deleted).map((lot) => lot.id) || [],
  };
}
