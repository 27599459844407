import { Device } from 'utils/device';

export const validateEmail = (email: string): boolean => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isTrue = (value: string | boolean = ''): boolean => {
  return `${value}`.toLowerCase() === 'true';
};

export const isNumber = (value: number | string): boolean => {
  const re = /^-?\d+\.?\d*$/;
  return re.test(String(value));
};

export enum VERSION {
  TWO = '2.0',
  FIVE = '5.0',
}

export const isDeviceVersion = (version: VERSION, device?: Device | null): boolean => device?.ver === version;

export const isDeviceVersionAtLeast = (version: VERSION, device: Device | null): boolean =>
  Number(device?.ver) >= Number(version);
