import TareasPendientesBanner from 'components/tareas-pendientes-banner';
import TableView from 'design-system/table-view';
import ViewHeader from 'design-system/view-header';
import { useColumns } from 'hooks/useColumns';
import { useModal } from 'hooks/useModal';
import { useSelector } from 'react-redux';
import { selectDeviceSelectedStockIngredients } from 'store/devices/devicesSlice';
import ModalEditarIngrediente from './modal-editar-ingrediente';

function Ingredientes() {
  const selectedStockIngredients = useSelector(selectDeviceSelectedStockIngredients);
  const { columns, onColumnsViewChange } = useColumns('columnsStock');
  const [isOpenEditarIngrediente, onOpenEditarIngrediente, onCloseEditarIngrediente] = useModal();

  return (
    <>
      <ModalEditarIngrediente isOpen={isOpenEditarIngrediente} onClose={onCloseEditarIngrediente} />
      <ViewHeader
        title="Ingredientes"
        action={{
          label: 'Editar Ingrediente',
          onClick: () => onOpenEditarIngrediente(null),
          disabled: !selectedStockIngredients?.length,
          isProtected: true,
        }}
      />
      <div className="px-8">
        <TareasPendientesBanner />
      </div>
      <TableView
        data={selectedStockIngredients || []}
        columns={columns}
        onColumnViewChange={onColumnsViewChange}
        options={{
          print: false,
          download: false,
          filter: false,
          viewColumns: false,
          pagination: false,
        }}
      />
    </>
  );
}

export default Ingredientes;
