import {
  filterByTableFilters,
  Filters,
  RowExtraAttributes,
  Semaforo,
  TableSubsumaResultadoRow,
  TableSubsumaRowValue,
} from 'components/table/TableSubsum';
import {
  ACTION_DOWNLOAD_MANUAL,
  DeviceDataEvent,
  DeviceEventDownload,
  filterAndSortyByDownload,
  filterValuesInData,
} from 'utils/device/event/sip';
import { redondearDosDecimales } from 'utils/helpers/math';

type AttributeToSum = 'toDownload' | 'downloaded' | 'diferenciaKg';
type ExtraAttributes = 'lotesDescargados';
type FilterableAttributes = 'user' | 'downloadGuide';

export type DeviceEventDownloadSummed = DeviceEventDownload &
  TableSubsumaRowValue & {
    isSelected: boolean;
    finReceta: boolean;
    diferenciaKg: number;
    porcentajeKg: number;
    semaforo: Semaforo[];
  };

type DeviceEventDownloadResultado = TableSubsumaResultadoRow<
  AttributeToSum,
  ExtraAttributes
> & {
  [key in FilterableAttributes]: string[];
};

export type DeviceEventDownloadFormatted =
  | DeviceEventDownloadSummed
  | DeviceEventDownloadResultado;

const attributesDescargasToSum: AttributeToSum[] = [
  'toDownload',
  'downloaded',
  'diferenciaKg',
];

function newAttributesToSumRow(
  isFinal: boolean = false
): DeviceEventDownloadResultado {
  return {
    ...attributesDescargasToSum.reduce(
      (acc, curr): DeviceEventDownloadResultado => ({
        ...acc,
        [curr]: [0, 0],
      }),
      {
        rowType: 'resultado',
        isResultadoFinal: isFinal,
      } as DeviceEventDownloadResultado
    ),
    extra: {} as RowExtraAttributes<ExtraAttributes, string>,
  };
}

export default function parseDataDescargas(
  data: DeviceDataEvent[] = [],
  filters: Filters = {}
): [
  DeviceEventDownloadFormatted[],
  { [key in FilterableAttributes]: DeviceEventDownloadFormatted[key][] }
] {
  const dataFilteredAndSortedByDownload = filterAndSortyByDownload(data);
  const [userValues, downloadGuideValues] = [
    filterValuesInData(dataFilteredAndSortedByDownload, 'user'),
    filterValuesInData(dataFilteredAndSortedByDownload, 'downloadGuide'),
  ];

  const downloadData = filterByTableFilters(
    dataFilteredAndSortedByDownload,
    filters
  );

  let currSubsuma = newAttributesToSumRow();
  let currTotal = newAttributesToSumRow(true);
  let currDownloadGuideCount = 0;
  let currDownloadGuideLotesDescargados = '';

  return [
    downloadData.reduce((acc, event, index): DeviceEventDownloadFormatted[] => {
      const { endGuide, downloadGuide, action, toDownload, downloaded } =
        event || {};
      let nextGuide =
        index + 1 < downloadData.length
          ? downloadData[index + 1].downloadGuide
          : '';
      const isFinReceta =
        endGuide === 'yes' ||
        endGuide === 'yesAnticipated' ||
        !!(downloadGuide && downloadGuide !== nextGuide);
      const porcentajeKg = redondearDosDecimales(
        (downloaded * 100) / toDownload - 100
      );

      const currRow: DeviceEventDownloadSummed = {
        ...event,
        finReceta: isFinReceta,
        diferenciaKg: toDownload - downloaded,
        porcentajeKg,
        isSelected: !!(action !== ACTION_DOWNLOAD_MANUAL), // TODO: tomar el isSelected del toggle de la tabla
        isAccionManual: action === ACTION_DOWNLOAD_MANUAL,
        rowType: 'value',
        semaforo: [
          {
            key: 'porcentajeKg',
            isWarning:
              Math.abs(porcentajeKg) > 5 && Math.abs(porcentajeKg) < 15,
            isDanger: Math.abs(porcentajeKg) >= 15,
          },
        ],
      };

      if (action !== ACTION_DOWNLOAD_MANUAL) currDownloadGuideCount++;

      let currSubsumaRow = sumRowAttributes(currSubsuma, currRow);
      if (isFinReceta) {
        currSubsuma = newAttributesToSumRow();
        currDownloadGuideLotesDescargados = `${currDownloadGuideCount} de ${event.lotsQuantity}`;
        currDownloadGuideCount = 0;
      }
      currTotal = sumRowAttributes(currTotal, currRow);

      return [
        ...acc,
        currRow,
        ...(isFinReceta
          ? [
              {
                ...currSubsumaRow,
                user: userValues,
                downloadGuide: downloadGuideValues,
                isAnticipated: endGuide === 'yesAnticipated',
                extra: {
                  lotesDescargados: {
                    label: 'Cantidad Lotes Descargados',
                    value: currDownloadGuideLotesDescargados,
                  },
                },
              },
            ]
          : []),
        ...(index === downloadData.length - 1
          ? [
              {
                ...currTotal,
                user: userValues,
                downloadGuide: downloadGuideValues,
              },
            ]
          : []),
      ];
    }, [] as DeviceEventDownloadFormatted[]),
    { user: userValues, downloadGuide: downloadGuideValues },
  ];
}

function sumRowAttributes(
  currSubsuma: DeviceEventDownloadResultado,
  row: DeviceEventDownloadSummed
): DeviceEventDownloadResultado {
  let newCurrSubsuma = { ...currSubsuma };
  attributesDescargasToSum.forEach((key) => {
    if (row.isSelected && row[key])
      if (row[key] >= 0) newCurrSubsuma[key][1] += row[key];
      else newCurrSubsuma[key][0] += row[key];
  });
  return newCurrSubsuma;
}
