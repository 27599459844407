import { customBodyRenderDate } from 'utils/helpers/body-renders';
import {
  customBodyRenderSelectedColumn,
  customBodyRenderSummedAttribute,
} from 'components/table/TableSubsum';

export const columnsDescargas = [
  {
    name: 'selected',
    label: 'Sumar',
    options: {
      filter: false,
      sort: false,
      viewColumns: false,
      customBodyRender: customBodyRenderSelectedColumn,
    },
  },
  {
    name: 'date',
    label: 'Fecha',
    options: {
      filter: false,
      sort: false,
      viewColumns: false,
      customBodyRender: customBodyRenderDate,
    },
  },
  { name: 'lot', label: 'Lote', options: { filter: false, sort: false } },
  {
    name: 'toDownload',
    label: 'Kg Teorico',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'downloaded',
    label: 'Kg Descargados',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'downloadGuide',
    label: 'Guía',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'user',
    label: 'Usuario',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'diferenciaKg',
    label: 'Diferencia Kg',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'porcentajeKg',
    label: '%Kg',
    options: { filter: false, sort: false },
  },
];
