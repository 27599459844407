import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from 'store/store';
import { PumpUser, PumpVehicle } from 'utils/device';
import { DeviceEventCMAS } from 'utils/device/event/cmas';
import {
  DeviceDataEvent,
  DeviceEventControlMessage,
  DeviceEventDownload,
  DeviceEventGeneralLocation,
  DeviceEventLoad,
  DeviceEventMixing,
} from 'utils/device/event/sip';
import { DeviceEvent } from 'utils/device/event/sip/event';
import { Lot } from 'utils/device/lot';
import { DeviceStock, Ingredient } from 'utils/device/stock';
import { today, todayMinusSixHours } from 'utils/helpers';

export const TAG_USER = 'User';
export const TAG_DEVICE = 'Device';
export const TAG_DEVICE_DATA = 'DeviceData';
export const TAG_COLUMNA = 'Columna';
export const TAG_CONTROL_MESSAGES = 'ControlMessages';
export const TAG_DEVICE_EVENT_HISTORY = 'EventHistory';
export const TAG_DEVICE_DIETS_HISTORY = 'DietsHistory';
export const TAG_DEVICE_UNLOAD_HISTORY = 'UnloadHistory';

export const api = createApi({
  reducerPath: 'devicesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).config.token;
      if (token) {
        headers.set('authorization', token);
      }

      return headers;
    },
  }),
  tagTypes: [
    TAG_USER,
    TAG_DEVICE,
    TAG_DEVICE_DATA,
    TAG_COLUMNA,
    TAG_CONTROL_MESSAGES,
    TAG_DEVICE_EVENT_HISTORY,
    TAG_DEVICE_DIETS_HISTORY,
    TAG_DEVICE_UNLOAD_HISTORY,
  ],
  endpoints: (builder) => ({
    getDeviceData: builder.query<DeviceDataEvent[], { serialNumber: string; minDate: string; maxDate: string }>({
      query: ({ serialNumber, minDate, maxDate }) => ({
        url: `deviceData/get/${serialNumber}?minDate=${minDate}&maxDate=${maxDate}`,
      }),
      providesTags: [TAG_DEVICE_DATA],
    }),
    getDeviceLoadData: builder.query<DeviceEventLoad[], { serialNumber: string; minDate: string; maxDate: string }>({
      query: ({ serialNumber, minDate, maxDate }) => ({
        url: `deviceData/get/loads/${serialNumber}?minDate=${minDate}&maxDate=${maxDate}`,
      }),
      providesTags: [TAG_DEVICE_DATA],
    }),
    getDeviceDownloadData: builder.query<
      DeviceEventDownload[],
      { serialNumber: string; minDate: string; maxDate: string }
    >({
      query: ({ serialNumber, minDate, maxDate }) => ({
        url: `deviceData/get/unloads/${serialNumber}?minDate=${minDate}&maxDate=${maxDate}`,
      }),
      providesTags: [TAG_DEVICE_DATA],
    }),
    getDeviceMixingData: builder.query<DeviceEventMixing[], { serialNumber: string; minDate: string; maxDate: string }>(
      {
        query: ({ serialNumber, minDate, maxDate }) => ({
          url: `deviceData/get/mixing/${serialNumber}?minDate=${minDate}&maxDate=${maxDate}`,
        }),
        providesTags: [TAG_DEVICE_DATA],
      }
    ),
    getDeviceLocationData: builder.query<
      DeviceEventGeneralLocation[],
      { serialNumber: string; minDate: string; maxDate: string }
    >({
      query: ({ serialNumber, minDate, maxDate }) => ({
        url: `deviceData/get/location/${serialNumber}?minDate=${minDate}&maxDate=${maxDate}`,
      }),
      providesTags: [TAG_DEVICE_DATA],
    }),
    toggleSum: builder.mutation<any, DeviceEventLoad>({
      query: (event) => ({
        url: 'sipnStock/toggleSum',
        method: 'POST',
        body: event,
      }),
      invalidatesTags: [TAG_DEVICE_DATA],
    }),
    updateStockIngredient: builder.mutation<any, { stockId: string; ingredient: Ingredient; detail?: string }>({
      query: ({ stockId, ingredient, detail }) => ({
        url: 'sipnStock/updateIngredient',
        method: 'POST',
        body: { stockId, ingredient, detail },
      }),
      invalidatesTags: [TAG_USER],
    }),
    deleteStockIngredient: builder.mutation<any, { stock: DeviceStock }>({
      query: ({ stock }) => ({
        url: 'sipnStock/update',
        method: 'POST',
        body: stock,
      }),
      invalidatesTags: [TAG_USER],
    }),
    updateLot: builder.mutation<any, { serialNumber: string; lot: Lot }>({
      query: ({ serialNumber, lot }) => ({
        url: `device/update/lot/${serialNumber}`,
        method: 'POST',
        body: lot,
      }),
      invalidatesTags: [TAG_USER],
    }),
    updateLotFeederState: builder.mutation<
      any,
      {
        serialNumber: string;
        lotId: number;
        typeOfCorrection: string;
        internalOption: string;
        inputValue: number;
        unloadGuide: number;
      }
    >({
      query: ({ serialNumber, lotId, typeOfCorrection, internalOption, inputValue, unloadGuide }) => {
        return {
          url: `device/update/lot/remoteFeederState/${serialNumber}`,
          method: 'POST',
          body: {
            selectedLot: lotId,
            typeOfCorrection,
            internalOption,
            inputValue,
            unloadGuide,
          },
        };
      },
      invalidatesTags: [TAG_USER],
    }),
    getDeviceIngredientHistory: builder.query<
      DeviceEventLoad[],
      { serialNumber: string; minDate: string; maxDate: string }
    >({
      query: ({ serialNumber, minDate, maxDate }) => ({
        url: `deviceData/get/ingredientHistory/${serialNumber}?minDate=${minDate}&maxDate=${maxDate}`,
      }),
      providesTags: [TAG_DEVICE_DATA],
    }),
    getDeviceControlMessages: builder.query<
      DeviceEventControlMessage[],
      { serialNumber: string; minDate: string; maxDate: string }
    >({
      query: ({ serialNumber, minDate, maxDate }) => ({
        url: `controlMessage/get/${serialNumber}?minDate=${minDate}&maxDate=${maxDate}`,
      }),
      providesTags: [TAG_CONTROL_MESSAGES],
    }),
    getDeviceLatestControlMessages: builder.query<DeviceEventControlMessage[], { serialNumber: string }>({
      query: ({ serialNumber }) => ({
        url: `controlMessage/get/${serialNumber}?minDate=${todayMinusSixHours}&maxDate=${today}`,
      }),
      providesTags: [TAG_CONTROL_MESSAGES],
    }),
    // Only return the latest message
    getDeviceLatestControlMessage: builder.query<DeviceEventControlMessage, { serialNumber: string }>({
      query: ({ serialNumber }) => ({
        url: `controlMessage/get/latest/${serialNumber}`,
      }),
      providesTags: [TAG_CONTROL_MESSAGES],
    }),
    getDeviceEventsHistory: builder.query<DeviceEvent[], { serialNumber: string; minDate: string; maxDate: string }>({
      query: ({ serialNumber, minDate, maxDate }) => ({
        url: `events/get/${serialNumber}?minDate=${minDate}&maxDate=${maxDate}`,
      }),
      providesTags: [TAG_DEVICE_EVENT_HISTORY],
    }),
    getDeviceStockHistory: builder.query<DeviceEventLoad[], { serialNumber: string; minDate: string; maxDate: string }>(
      {
        query: ({ serialNumber, minDate, maxDate }) => ({
          url: `stockHistory/get/${serialNumber}?minDate=${minDate}&maxDate=${maxDate}`,
        }),
        providesTags: [TAG_DEVICE_DATA],
      }
    ),
    getDeviceCMASData: builder.query<DeviceEventCMAS[], { serialNumber: string; minDate: string; maxDate: string }>({
      query: ({ serialNumber, minDate, maxDate }) => ({
        url: `deviceData/get/${serialNumber}?minDate=${minDate}&maxDate=${maxDate}`,
      }),
      providesTags: [TAG_DEVICE_DATA],
    }),
    getDeviceTasks: builder.query<DeviceEventCMAS[], { serialNumber: string }>({
      query: ({ serialNumber }) => ({ url: `tasks/${serialNumber}` }),
      providesTags: [TAG_DEVICE_DATA],
    }),

    addGacUser: builder.mutation<any, { user: PumpUser; serialNumber: string }>({
      query: ({ user, serialNumber }) => {
        return {
          url: `device/gac/addUser/${serialNumber}`,
          method: 'POST',
          body: user,
        };
      },
      invalidatesTags: [TAG_USER],
    }),
    updateGacUser: builder.mutation<any, { user: PumpUser; serialNumber: string }>({
      query: ({ user, serialNumber }) => {
        return {
          url: `device/gac/updateUser/${serialNumber}`,
          method: 'POST',
          body: user,
        };
      },
      invalidatesTags: [TAG_USER],
    }),
    deleteGacUser: builder.mutation<any, { user: PumpUser; serialNumber: string }>({
      query: ({ user, serialNumber }) => ({
        url: `device/gac/removeUser/${serialNumber}`,
        method: 'POST',
        body: user,
      }),
      invalidatesTags: [TAG_DEVICE],
    }),
    updateGacVehicle: builder.mutation<any, { vehicle: PumpVehicle; serialNumber: string }>({
      query: ({ vehicle, serialNumber }) => {
        return {
          url: `device/gac/updateVehicle/${serialNumber}`,
          method: 'POST',
          body: vehicle,
        };
      },
      invalidatesTags: [TAG_USER],
    }),
    updateGacAddFuel: builder.mutation<any, { fuel: string; serialNumber: string }>({
      query: ({ fuel, serialNumber }) => {
        return {
          url: `device/gac/addFuel/${serialNumber}`,
          method: 'POST',
          body: { fuel },
        };
      },
      invalidatesTags: [TAG_USER],
    }),
    updateGacChangePrice: builder.mutation<any, { price: string; serialNumber: string }>({
      query: ({ price, serialNumber }) => {
        return {
          url: `device/gac/changePrice/${serialNumber}`,
          method: 'POST',
          body: { price },
        };
      },
      invalidatesTags: [TAG_USER],
    }),

    // ITH - TIMER
    updateITHTimer: builder.mutation<
      any,
      { id: string; sector: string; start: string; stop: string; serialNumber: string }
    >({
      query: ({ id, sector, start, stop, serialNumber }) => {
        return {
          url: `device/ithc/updateTimer/${serialNumber}`,
          method: 'POST',
          body: { id, sector, start, stop },
        };
      },
      invalidatesTags: [TAG_USER],
    }),
  }),
});

export const {
  useGetDeviceDataQuery,
  useGetDeviceLoadDataQuery,
  useGetDeviceDownloadDataQuery,
  useGetDeviceMixingDataQuery,
  useGetDeviceLocationDataQuery,
  useToggleSumMutation,
  useUpdateStockIngredientMutation,
  useDeleteStockIngredientMutation,
  useGetDeviceIngredientHistoryQuery,
  useGetDeviceControlMessagesQuery,
  useGetDeviceLatestControlMessagesQuery,
  useGetDeviceLatestControlMessageQuery,
  useGetDeviceEventsHistoryQuery,
  useUpdateLotMutation,
  useUpdateLotFeederStateMutation,
  useGetDeviceStockHistoryQuery,
  useGetDeviceCMASDataQuery,
  useGetDeviceTasksQuery,
  useAddGacUserMutation,
  useUpdateGacUserMutation,
  useDeleteGacUserMutation,
  useUpdateGacVehicleMutation,
  useUpdateGacAddFuelMutation,
  useUpdateGacChangePriceMutation,
  useUpdateITHTimerMutation,
} = api;
